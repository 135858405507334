import { EuiButton, EuiCard, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiTitle } from "@elastic/eui"
import { useEffect, useState } from "react"
import { API_PATH, useApi } from "../../effects/useApi"
import { Media } from "../../types"

export default function Certificates({ path, formation_instance_id, refetch_formation }: { path: string, formation_instance_id?: string, refetch_formation?: () => void }) {

	const [attestations, setAttestations] = useState<Media[]>([])

	const [fetch_attestations] = useApi('get', `certificates/${formation_instance_id}`, (err, attestations) => {

		if (err) {
			console.error(err)
			return;
		}

		setAttestations(attestations)
	})

	useEffect(() => {
		fetch_attestations()
	}, [formation_instance_id])

	return <div>
		{attestations.length === 0 && <EuiEmptyPrompt
			title={<h2>Aucune attestation</h2>}
			body={<p>Vous n'avez pas encore reçu d'attestation concernant cette évaluation.</p>}
		/>}
		{attestations?.map(attestation => <>
			<EuiPanel title={attestation.description}>

				<EuiFlexGroup>
					<EuiFlexItem>


						<EuiTitle>
							<h3>{attestation.description}</h3>
						</EuiTitle>

						{/* @ts-ignore */}
						Le {new Date(attestation.createdAt).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' })} à {new Date(attestation.createdAt).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' })}
					</EuiFlexItem>

					<EuiFlexItem grow={false}>
						<a href={API_PATH + "files/certificates/" + attestation._id + '.pdf'} target="_blank">
							<EuiButton>Télécharger</EuiButton>
						</a>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiPanel>
			<EuiSpacer size="m" />
		</>)}
	</div>
}