import { EuiButton, EuiDescribedFormGroup, EuiRange, EuiAccordion, EuiSwitch, EuiFormRow, EuiSelect, EuiCard, EuiFieldText, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiTextArea, EuiTitle, EuiHorizontalRule, EuiSuperSelect } from '@elastic/eui';
import { Link, navigate, Router } from '@reach/router';
import _ from 'lodash';
import { Types } from 'mongoose';
// import { Button } from 'antd';
import { useEffect, useState } from 'react';
import RichText from '../../../../../components/RichText';
import { useApi } from '../../../../../effects/useApi';
import { TheoricEvaluationModule, QuizzPossibleAnswer } from "../../../../../types";
import { Quizz, QuizzQuestion } from '../../../../../types/Quizz';

export default function TheoricEvaluationModuleEditor({ module, formation_id, reload, reload_formation, setDirty }: { module: TheoricEvaluationModule, formation_id: string, reload: () => void, reload_formation: () => void, setDirty: (value: boolean) => void }) {

	return <div>
		<Router primary={false}>
			{/* <EditQuizz path="quizzes/:quizz_id" module_id={module._id as string} /> */}
			<QuizzList path="*" module_id={module._id as string} quizzes={module.quizzes as unknown as Quizz[]} reload={reload} module={module} setDirty={setDirty} />
		</Router>
	</div>
}

export function QuizzList({ path, module_id, reload, quizzes, module, setDirty }: { path: string, module_id: string, reload: () => void, quizzes?: Array<Quizz>, module: TheoricEvaluationModule, setDirty: (value: boolean) => void }) {

	const [create_quizz] = useApi('POST', `theoric_evaluation_modules/${module_id}/quizzes`, (err, quizz) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setDirty(true)
	})


	return <Router primary={false}>
		{/* <EditQuizz path="quizzes/:quizz_id" module_id={module_id} setDirty={setDirty} /> */}
		<List path="/" module_id={module_id} reload={reload} quizzes={quizzes} module={module} setDirty={setDirty} />
	</Router>
}

function List({ path, module_id, reload, quizzes, module, setDirty }: { path: string, module_id: string, reload: () => void, quizzes?: Array<Quizz>, module: TheoricEvaluationModule, setDirty: (value: boolean) => void }) {

	const [minimal_score, setMinimalScore] = useState<number>(module.minimal_ratio)
	const [strategy, setStrategy] = useState<string | null>(module.strategy as unknown as string)

	const [setNewRatio] = useApi('PUT', `theoric_evaluation_modules/${module_id}/minimal_ratio`, (err, module) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setDirty(true)
	})

	const [updateStrategy] = useApi('PUT', `theoric_evaluation_modules/${module_id}/strategy`, (err, module) => {
		if (err) {
			console.error(err)
			return
		}
		reload()
		setDirty(true)
	})


	return <>
		<EuiFlexGroup>
			<EuiFlexItem>
				<EuiTitle size="m">
					<h2>{module.header}</h2>
				</EuiTitle>
			</EuiFlexItem>
		</EuiFlexGroup>

		<EuiSpacer size="xxl" />

		<EuiDescribedFormGroup
			title={<h3>Score minimum (%)</h3>}
			description={
				<p>
					Pourcentage minimal de bonnes réponses pour valider le module
				</p>
			}
		>
			<EuiRange fullWidth step={10} showTicks value={minimal_score} onChange={(e: any) => { setMinimalScore(e.target.value); setNewRatio({ minimal_ratio: e.target.value }) }} />
		</EuiDescribedFormGroup>

		<EuiDescribedFormGroup
			title={<h3>Sélection du questionnaire</h3>}
			description={
				<p>
					Choisissez le questionnaire que les opérateurs devront réaliser pour valider le module.<br /><Link to="../../quizzes">Bibliothèque de questionnaires</Link>
				</p>
			}>
			<EuiSuperSelect
				options={[{
					value: 'null',
					inputDisplay: <strong>Sélection aléatoire</strong>
				}, ...quizzes.filter(quizz => quizz.is_valid !== false).map((quizz, i) => ({
					value: quizz._id as unknown as string,
					inputDisplay: `Questionnaire ${i + 1}`,
				}))]}
				valueOfSelected={strategy || 'null'}
				onChange={value => { setStrategy(value === 'null' ? null : value); updateStrategy({ strategy: value === 'null' ? null : value }) }}
			/>
		</EuiDescribedFormGroup>

		<EuiSpacer size="xxl" />
		{/* 
		<EuiFlexGrid columns={4}>
			{quizzes?.map((quizz, i) => {
				return <EuiFlexItem><Link to={'quizzes/' + quizz._id as string}>
					<EuiCard title={`Quizz ${i + 1}`}>

						{quizz.questions.length} question{quizz.questions.length > 1 ? 's' : ''}
					</EuiCard></Link>
				</EuiFlexItem>
			})}
		</EuiFlexGrid> */}

		<EuiSpacer size="xxl" />
	</>
}
