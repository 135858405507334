import "@elastic/eui/dist/eui_theme_light.css";
import { LocationProvider, Router } from "@reach/router";

import { EuiProvider, EuiThemeProvider } from "@elastic/eui";
import { useCallback, useContext, useEffect } from "react";
import "./App.css";
import { UserContext } from "./context/UserContext";
import { API_PATH } from "./effects/useApi";
import Connection from "./pages/Connection";
import Home from "./pages/Home";
import { ResetPassword } from "./components/Login";

function App() {
	const [userContext, setUserContext] = useContext(UserContext);

	const verifyUser = useCallback(() => {
		const refresh_token = localStorage.getItem("auth_data") ? JSON.parse(localStorage.getItem("auth_data")!).refresh_token : null;

		fetch(API_PATH + "users/refresh_token", {
			method: "POST",
			credentials: "include",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ refresh_token }),
		}).then(async (response) => {
			if (response.ok) {
				const data = await response.json();

				setUserContext((oldValues) => {
					console.log("REFRESH AUTH DATA 1", oldValues, data);
					localStorage.setItem(
						"auth_data",
						JSON.stringify({ ...oldValues, refresh_token: data.refresh_token, token: data.token, expires_on: new Date().getTime() + 5 * 60 * 1000, loggedAs: undefined })
					);
					return { ...oldValues, refresh_token: data.refresh_token, token: data.token };
				});
			} else {
				setUserContext((oldValues) => {
					console.log("REFRESH AUTH DATA2 ", oldValues, null);
					localStorage.setItem("auth_data", JSON.stringify({ ...oldValues, token: null, expires_on: new Date().getTime(), loggedAs: undefined }));
					return { ...oldValues, token: null };
				});
			}
			// call refreshToken every 5 minutes to renew the authentication token.
			setTimeout(verifyUser, 5 * 60 * 1000);
		});
	}, [setUserContext]);

	const verifyLoggedAs = useCallback(() => {
		const refresh_token = sessionStorage.getItem("logged_as") ? JSON.parse(sessionStorage.getItem("logged_as")!).refresh_token : null;

		fetch(API_PATH + "users/refresh_token", {
			method: "POST",
			// credentials: 'include',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ refresh_token }),
		}).then(async (response) => {
			if (response.ok) {
				const data = await response.json();

				// setUserContext(oldValues => {
				// 	console.log("REFRESH AUTH DATA 1", oldValues, data)
				// 	localStorage.setItem("auth_data", JSON.stringify({ ...oldValues, refresh_token: data.refresh_token, token: data.token, expires_on: new Date().getTime() + (5 * 60 * 1000), loggedAs: undefined }))
				// 	return { ...oldValues, refresh_token: data.refresh_token, token: data.token }
				// })

				const loggedAs = sessionStorage.getItem("logged_as") ? JSON.parse(sessionStorage.getItem("logged_as")) : null;

				if (loggedAs) {
					setUserContext((oldValues) => {
						sessionStorage.setItem("logged_as", JSON.stringify({ ...loggedAs, token: data.token, refresh_token: data.refresh_token, expires_on: new Date().getTime() + 5 * 60 * 1000 }));
						return { ...oldValues, loggedAs: { ...loggedAs, token: data.token, refresh_token: data.refresh_token } };
					});
				}
			} else {
				// setUserContext((oldValues) => {
				// 	console.log("REFRESH AUTH DATA2 ", oldValues, null);
				// 	localStorage.setItem("auth_data", JSON.stringify({ ...oldValues, token: null, expires_on: new Date().getTime(), loggedAs: undefined }));
				// 	return { ...oldValues, token: null };
				// });

				const loggedAs = sessionStorage.getItem("logged_as") ? JSON.parse(sessionStorage.getItem("logged_as")) : null;

				if (loggedAs) {
					setUserContext((oldValues) => {
						sessionStorage.setItem("logged_as", JSON.stringify({ ...loggedAs, token: null, refresh_token: null, expires_on: new Date().getTime() }));
						return { ...oldValues, loggedAs: { ...loggedAs, token: null, refresh_token: null } };
					});
				}
			}
			// call refreshToken every 5 minutes to renew the authentication token.
			setTimeout(verifyLoggedAs, 5 * 60 * 1000);
		});
	}, [setUserContext]);

	useEffect(() => {
		verifyUser();
		verifyLoggedAs();
	}, [verifyUser, verifyLoggedAs]);

	const syncLogout = useCallback((event: StorageEvent) => {
		if (event.key === "logout") {
			window.location.reload();
		}
	}, []);

	const logoutHandler = () => {
		fetch(API_PATH + "users/logout", {
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userContext.token}`,
			},
		}).then(async (response) => {
			setUserContext((oldValues) => {
				console.log("REFRESH AUTH DATA3 ", oldValues, null);
				localStorage.setItem("auth_data", JSON.stringify({ ...oldValues, details: undefined, token: null, refresh_token: null, expires_on: new Date().getTime(), loggedAs: undefined }));
				sessionStorage.removeItem("logged_as");
				return { ...oldValues, details: undefined, token: null, refresh_token: null };
			});
			// window.localStorage.setItem("logout", Date.now().toString())
		});
	};

	useEffect(() => {
		window.addEventListener("storage", syncLogout);
		return () => {
			window.removeEventListener("storage", syncLogout);
		};
	}, [syncLogout]);

	if (userContext.token === null)
		return (
			<EuiProvider colorMode="light">
				<Router>
					<ResetPassword path="/reinit_password/:reset_key" />
					<Connection path="*" />
				</Router>
			</EuiProvider>
		);
	else if (userContext.token)
		return (
			<EuiProvider colorMode="light">
				<LocationProvider>
					{/* {JSON.stringify(userContext)} */}
					<Home path="" logout={logoutHandler} />
				</LocationProvider>
			</EuiProvider>
		);
	else return <div>Loading...</div>;
}

export default App;
