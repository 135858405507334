import { Router } from "@reach/router";
import EditLaboratory from "./EditLaboratory";
import ListLaboratories from "./ListLaboratories";

export default function LmsAdmin({ path }: { path: string }) {

	return <Router primary={false}>
		{/* <EditLaboratory path="laboratories/:laboratory_id/*" /> */}
		<ListLaboratories path="*" />
	</Router>
}