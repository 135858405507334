import * as Sentry from "@sentry/react";
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom/client';
import App from './App';
import { UserProvider } from './context/UserContext';
import './index.css';
import reportWebVitals from './reportWebVitals';

Sentry.init({
	dsn: "https://50ef970dbd88405f89bf7f924a8a2f61@o297994.ingest.sentry.io/4503925184856064",
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
});


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(<UserProvider><App /></UserProvider>);

reportWebVitals();
