import {
	EuiButton,
	EuiCard,
	EuiDescribedFormGroup,
	EuiFieldText,
	EuiFlexGrid,
	EuiFlexItem,
	EuiFormRow,
	EuiPageTemplate,
	EuiPanel,
	EuiSpacer,
	EuiTextArea,
	EuiTitle,
} from "@elastic/eui";
import { navigate, Router, useMatch } from "@reach/router";
import { useEffect, useState } from "react";
import { useApi } from "../../effects/useApi";
import { Laboratory } from "../../types";
import EditLaboratory from "./EditLaboratory";

export default function ListLaboratories({
	path,
	navigate,
}: {
	path: string;
	navigate?: any;
}) {
	const [laboratories, setLaboratories] = useState<
		Array<Laboratory> | undefined
	>(undefined);

	const [fetch_laboratories] = useApi("GET", "laboratories", (err, labs) => {
		if (!err) {
			setLaboratories(labs);
		}
	});

	const [filter, setFilter] = useState<string>("");

	const match = useMatch("/:laboratory_id/*") as { laboratory_id?: string };

	const laboratory_id = match?.laboratory_id;

	useEffect(() => {
		fetch_laboratories();
	}, []);

	return (
		<EuiPageTemplate style={{ maxHeight: 100 }}>
			<EuiPageTemplate.Sidebar minWidth={350}>
				<EuiFieldText
					placeholder="Filtre"
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
				/>

				<EuiSpacer size="m" />

				<EuiFlexGrid columns={1} gutterSize="s">
					<EuiFlexItem>
						<EuiCard
							title="+ Ajouter une entreprise"
							titleSize="xs"
							paddingSize="m"
							textAlign="center"
							onClick={() => navigate("")}
							hasBorder
						/>
					</EuiFlexItem>

					<EuiSpacer size="m" />
					{laboratories
						?.filter(
							(lab) =>
								filter === "" ||
								lab.name
									.toLowerCase()
									.indexOf(filter.toLowerCase()) > -1
						)
						.map((laboratory) => {
							return (
								<EuiFlexItem>
									<EuiCard
										title={laboratory.name}
										titleSize="xs"
										paddingSize="s"
										textAlign="left"
										description={laboratory.siret}
										onClick={() => navigate(laboratory._id)}
										hasBorder={
											laboratory_id === laboratory._id
										}
										display={
											laboratory._id === laboratory_id
												? "plain"
												: "subdued"
										}
									/>
								</EuiFlexItem>
							);
						})}
				</EuiFlexGrid>
			</EuiPageTemplate.Sidebar>

			<Router primary={false}>
				<EditLaboratory path=":laboratory_id/*" />
				<Home path="/" refetch={fetch_laboratories} />
			</Router>
		</EuiPageTemplate>
	);
}

function Home({ path, refetch }: { path: string; refetch: () => void }) {
	const [name, setName] = useState<string>("");

	const [siret, setSiret] = useState<string>("");
	const [address, setAddress] = useState<string>("");
	const [city, setCity] = useState<string>("");
	const [postal_code, setPostalCode] = useState<string>("");
	const [phone, setPhone] = useState<string>("");
	const [country, setCountry] = useState<string>("");

	const [create_labo, loading] = useApi(
		"POST",
		"laboratories",
		async (err, lab) => {
			if (!err) {
				setName("");
				setSiret("");
				setAddress("");
				setCity("");
				setPostalCode("");
				setPhone("");
				setCountry("");

				await refetch();

				navigate(lab._id);
			}
		}
	);

	return (
		<EuiPageTemplate.Section>
			<EuiTitle size="l">
				<h1>Bienvenue sur 360skillvue, admin</h1>
			</EuiTitle>

			<EuiSpacer size="m" />

			<EuiPanel hasBorder>
				<EuiTitle>
					<h2>Créer une entreprise</h2>
				</EuiTitle>

				<EuiSpacer size="l" />

				<EuiDescribedFormGroup
					fullWidth
					title={<h3>Nom de l'entreprise</h3>}
					description="Le nom de l'entreprise tel qu'il sera affiché sur les documents."
				>
					<EuiFormRow fullWidth>
						<EuiFieldText
							required
							fullWidth
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiSpacer size="m" />

				<EuiDescribedFormGroup
					fullWidth
					title={<h3>Numéro de Siret</h3>}
				>
					<EuiFormRow fullWidth>
						<EuiFieldText
							required
							fullWidth
							value={siret}
							onChange={(e) => setSiret(e.target.value)}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiSpacer size="m" />

				<EuiDescribedFormGroup
					fullWidth
					title={<h3>Numéro de téléphone</h3>}
				>
					<EuiFormRow fullWidth>
						<EuiFieldText
							required
							fullWidth
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiSpacer size="m" />

				<EuiDescribedFormGroup
					fullWidth
					title={<h3>Coordonnées postales</h3>}
				>
					<EuiFormRow label="Adresse" fullWidth>
						<EuiTextArea
							required
							fullWidth
							value={address}
							onChange={(e) => setAddress(e.target.value)}
						/>
					</EuiFormRow>

					<EuiFormRow label="Code postal" fullWidth>
						<EuiFieldText
							required
							fullWidth
							value={postal_code}
							onChange={(e) => setPostalCode(e.target.value)}
						/>
					</EuiFormRow>

					<EuiFormRow label="Ville" fullWidth>
						<EuiFieldText
							required
							fullWidth
							value={city}
							onChange={(e) => setCity(e.target.value)}
						/>
					</EuiFormRow>

					<EuiFormRow label="Pays" fullWidth>
						<EuiFieldText
							required
							fullWidth
							value={country}
							onChange={(e) => setCountry(e.target.value)}
						/>
					</EuiFormRow>
				</EuiDescribedFormGroup>

				<EuiDescribedFormGroup fullWidth title={<h2> </h2>}>
					<EuiFormRow>
						<EuiButton
							iconType="plus"
							isLoading={loading}
							fullWidth
							onClick={async () => {
								create_labo({
									name,
									siret,
									address,
									city,
									postal_code,
									phone,
									country,
								});
							}}
						>
							Créer l'entreprise
						</EuiButton>
					</EuiFormRow>
				</EuiDescribedFormGroup>
			</EuiPanel>
		</EuiPageTemplate.Section>
	);
}
