import React, { Dispatch, ReactElement, SetStateAction, useState } from "react";

export type UserContextInterface = [any, Dispatch<SetStateAction<{}>>];

const UserContext = React.createContext<UserContextInterface>([{}, () => {}]);

let initialState = localStorage.getItem("auth_data") ? JSON.parse(localStorage.getItem("auth_data")) : { token: null };
let loggedAs: {
	firstname: string;
	lastname: string;
	token: string;
	refresh_token: string;
	expires_on: number;
} | null = sessionStorage.getItem("logged_as") ? JSON.parse(sessionStorage.getItem("logged_as")) : null;

if (initialState.expires_on && initialState.expires_on < new Date().getTime()) {
	initialState.token = null;

	// window.location.reload()
}

const UserProvider = (props: { children: ReactElement }) => {
	const [state, setState] = useState({
		...initialState,
		loggedAs,
	});

	return <UserContext.Provider value={[state, setState]}>{props.children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
