import { useEffect, useState } from "react";
import { useApi } from "../../../../../effects/useApi";
import { PracticalEvaluationModule, User } from "../../../../../types";
import { EuiFlexGrid, EuiPanel, EuiFlexItem, EuiCard, EuiIcon, EuiFlexGroup, EuiTitle, EuiSpacer, EuiButton, EuiFieldText, EuiButtonIcon, EuiDescribedFormGroup, EuiRange } from "@elastic/eui";
import { Link } from "@reach/router";
import _ from "lodash";

interface Achievement {
	_id: string
	text: string

}

const update = _.throttle((cb, st) => { cb(st) }, 1000)

export default function PracticalEvaluationModuleEditor({ module, reload_formation, setDirty }: { module: PracticalEvaluationModule, reload_formation: () => void, setDirty: (value: boolean) => void }) {

	const [correctors, setCorrectors] = useState<User[]>([]);
	const [biologist_verificators, setBiologistVerificators] = useState<User[]>([]);

	const [fetch_correctors] = useApi('GET', `formations/${module.formation}/correctors`, (err, correctors) => {
		if (err) return;
		setCorrectors(correctors);
	})

	const [fetch_biologist_verificators] = useApi('GET', `formations/${module.formation}/biologist_verificators`, (err, biologist_verificators) => {
		if (err) return;
		setBiologistVerificators(biologist_verificators);
	})

	const [update_module] = useApi('PUT', `formations/${module.formation}/practical_evaluation_module/${module._id}`, (err, module) => {
		if (err) return;
	})


	const [achievements, setAchievements] = useState<Achievement[]>([]);
	const [minimal_ratio, setMinimalRatio] = useState<number>(0);

	useEffect(() => {
		fetch_correctors();
		fetch_biologist_verificators();

		if (module.achievements) {
			setAchievements(module.achievements);
		} else {
			setAchievements([]);
		}

		if (module.minimal_ratio) {
			setMinimalRatio(module.minimal_ratio);
		} else {
			setMinimalRatio(50);
		}

	}, [module])

	return <div>

		<EuiFlexGroup>
			<EuiFlexItem>
				<EuiTitle size="m">
					<h2>{module.header}</h2>
				</EuiTitle>
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				{/* <EuiButton fill onClick={() => { create_quizz() }} isLoading={adding_quizz}>Ajouter un quizz</EuiButton> */}
			</EuiFlexItem>
		</EuiFlexGroup>

		<EuiSpacer size="m" />

		<p>
			À chaque soumission d'une évaluation pratique, un des vérificateurs suivants sera désigné(e) aléatoirement. Pour ajouter/supprimer des vérificateurs, rendez-vous dans l'onglet <Link to="../../roles">Rôles</Link>.
		</p>

		<EuiSpacer size="xl" />

		<EuiTitle>
			<h3>Évaluateurs</h3>
		</EuiTitle>

		<EuiSpacer size="m" />

		<EuiFlexGrid columns={3}>

			{correctors.length === 0 && <EuiFlexItem>
				<EuiPanel>
					Aucun évaluateur n'a été ajouté à cette formation.
				</EuiPanel>
			</EuiFlexItem>}

			{correctors.map(corrector => <EuiFlexItem key={corrector.id}>
				<EuiCard
					layout="horizontal"
					icon={<EuiIcon size="m" type={'user'} />}
					title={corrector.firstname + " " + corrector.lastname}
					description={corrector.email}
					hasBorder
					onClick={() => { }}
				/>
			</EuiFlexItem>)}

		</EuiFlexGrid>

		<EuiSpacer size="xl" />

		<EuiTitle>
			<h3>Vérificateurs</h3>
		</EuiTitle>

		<EuiSpacer size="m" />

		<EuiFlexGrid columns={3}>

			{biologist_verificators.length === 0 && <EuiFlexItem>
				<EuiPanel>
					Aucun vérificateur n'a été ajouté à cette formation.
				</EuiPanel>
			</EuiFlexItem>}


			{biologist_verificators.map(biologist_verificator => <EuiFlexItem key={biologist_verificator.id}>
				<EuiCard
					layout="horizontal"
					icon={<EuiIcon size="m" type={'user'} />}
					title={biologist_verificator.firstname + " " + biologist_verificator.lastname}
					description={biologist_verificator.email}
					hasBorder
					onClick={() => { }}
				/>
			</EuiFlexItem>)}

		</EuiFlexGrid>

		<EuiSpacer size="xl" />

		<EuiFlexGroup>
			<EuiFlexItem>
				<EuiTitle>
					<h3>Consignes</h3>
				</EuiTitle>
			</EuiFlexItem>
			<EuiFlexItem grow={false}>
				<EuiButton iconType="plus" fill onClick={() => {
					const _id = Math.random().toString()
					setAchievements([...achievements, { _id: _id, text: "" }])
					update(update_module, { achievements: [...achievements, { _id: _id, text: "" }] });
				}}>Ajouter une compétence</EuiButton>
			</EuiFlexItem>

		</EuiFlexGroup>


		<EuiSpacer size="xxl" />

		<EuiDescribedFormGroup
			title={<h3>Score minimum (%)</h3>}
			description={
				<p>
					Pourcentage minimal de critères de compétence acquis à vérifier pour valider le module.
				</p>
			}
		>
			<EuiRange fullWidth step={10} showTicks value={minimal_ratio} onChange={(e: any) => {
				setMinimalRatio(e.target.value);
				update(update_module, { minimal_ratio: e.target.value });
				setDirty(true);
			}} />
		</EuiDescribedFormGroup>

		<EuiSpacer size="xxl" />


		<p>
			Indiquez les critères de compétence à vérifier pour cette évaluation pratique. Ces critères de compétence seront affichés aux étudiants lors de la soumission de leur évaluation et vérifiés par l'évaluateur.
		</p>

		<EuiSpacer size="m" />

		{/* {JSON.stringify(achievements)} */}

		{achievements.map(achievement => {
			return <>
				<EuiPanel key={achievement._id}>
					<EuiFlexGroup>
						<EuiFlexItem>
							<EuiFieldText value={achievement.text} fullWidth onChange={(e) => {
								const new_achievements = achievements.map(a => {
									if (a._id === achievement._id) {
										return { ...a, text: e.target.value }
									} else {
										return a;
									}
								})
								setAchievements(new_achievements);
								setDirty(true);

								update(update_module, { achievements: new_achievements });

								reload_formation()
							}} />
						</EuiFlexItem>
						<EuiFlexItem grow={false} style={{ placeContent: "center" }}>
							<EuiButtonIcon style={{ placeSelf: "center" }} iconType="trash" size="m" color="danger" onClick={() => {
								const new_achievements = achievements.filter(a => a._id !== achievement._id);
								setAchievements(new_achievements);
								setDirty(true);

								update(update_module, { achievements: new_achievements });

								reload_formation()
							}} />
						</EuiFlexItem>

					</EuiFlexGroup>
				</EuiPanel>
				<EuiSpacer size="m" />
			</>
		})}



	</div>
}

