import { Link, navigate, Router } from "@reach/router";
import { useContext, useEffect, useState } from "react";
import { API_PATH, useApi } from "../effects/useApi";
import Dashboard from "./Dashboard";

import { Laboratory, User } from "../types";

import {
	EuiButton,
	EuiButtonEmpty,
	EuiCallOut,
	EuiContextMenu,
	EuiFlexGroup,
	EuiFlexItem,
	EuiHeader,
	EuiHeaderLink,
	EuiHeaderSectionItemButton,
	EuiIcon,
	EuiImage,
	EuiListGroup,
	EuiListGroupItem,
	EuiNotificationBadge,
	EuiPopover,
	EuiText,
} from "@elastic/eui";
import Notifications from "./Notifications";
import Preview from "./Preview";
import { UserContext } from "../context/UserContext";

interface RolesInterface {
	is_admin: boolean;
	biologist_of: string[];
	biologist_verificator_of: string[];
	corrector_of: string[];
	formation_corrector_of: string[];
	formation_subreferent_of: string[];
	formation_qualitician_of: string[];
	member_of: string[];
	operator_of: string[];
	qualitician_of: string[];
	subreferent_of: string[];
	referent_of: string[];
	laboratory_subreferent_of: string[];
	laboratory_biologist_of: string[];
}

export default function Home({ path, logout }: { path: string; logout: () => void }) {
	const [me, setMe] = useState<User | null>(null);
	const [laboratories, setLaboratories] = useState<Array<any> | []>([]);

	const [roles, setRoles] = useState<RolesInterface | undefined>(undefined);

	const [fetch] = useApi("GET", "users/roles", async (err, roles) => {
		setRoles(roles);
	});

	const [fetch_me] = useApi("GET", "users/me", (err, user) => {
		if (!err) {
			setMe(user);

			// @ts-ignore
			// window.Intercom("boot", {
			// 	api_base: "https://api-iam.intercom.io",
			// 	app_id: "b98g5mvc",
			// 	name: user.firstname + " " + user.lastname,
			// });
		}
	});

	const [notif_count, setNotifCount] = useState<number>(0);

	const [fetch_notif_count] = useApi("GET", "notifications/count", (err, notifs) => {
		if (!err && notifs) {
			setNotifCount(notifs.count);
		}
	});

	const [fetch_laboratories] = useApi("GET", `users/laboratories`, (err, labs) => {
		if (!err) {
			setLaboratories(
				labs.map((lab: Laboratory) => ({
					label: lab.name,
					key: lab._id,
					logo: lab.logo,
					can_display_logo: lab.can_display_logo,
				}))
			);
		}
	});

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
	const closePopover = () => setIsPopoverOpen(false);

	useEffect(() => {
		fetch_me();
		fetch_laboratories();
		fetch_notif_count();
		fetch();

		setInterval(() => {
			fetch_notif_count();
			fetch_laboratories();
			fetch();
		}, 60 * 1000);
	}, []);

	const member_of = (roles?.member_of || []) as unknown as Laboratory[];

	const [userContext, setUserContext] = useContext(UserContext);

	return (
		<>
			<EuiHeader
				sections={[
					{
						items: [
							...(member_of || [])
								.filter((lab) => lab.logo && lab.can_display_logo)
								.map((lab) => {
									return (
										<EuiHeaderLink size="m" onClick={() => navigate("/")}>
											<img
												alt={"logo"}
												src={API_PATH + "videos/" + lab.logo}
												style={{
													marginTop: 6,
													maxHeight: 40,
												}}
											/>
										</EuiHeaderLink>
									);
								}),

							(member_of || []).filter((lab) => lab.logo && lab.can_display_logo).length === 0 && (
								<EuiHeaderLink size="m" onClick={() => navigate("/")}>
									<img src="/logo.png" alt="logo" className="h-8" width={70} style={{ marginTop: 4 }} />
								</EuiHeaderLink>
							),
						],
						borders: "right",
						breadcrumbProps: {
							"aria-label": "Header sections breadcrumbs",
						},
					},
					{
						items: [
							// <EuiPopover
							// 	button={< EuiButtonEmpty color="text" iconType="documentation" iconSide="left" onClick={onButtonClick} > Tutoriels</EuiButtonEmpty>
							// 	}
							// 	isOpen={isPopoverOpen}
							// 	closePopover={closePopover}
							// 	panelPaddingSize="none"
							// >
							// 	<EuiContextMenu
							// 		initialPanelId={0}
							// 		panels={[
							// 			{
							// 				id: 0,
							// 				title: 'Tutoriels',
							// 				items: [{
							// 					name: 'Ajouter des membres à votre entreprise',
							// 					onClick: () => { window.open("https://app.tango.us/app/workflow/Cr-er-mes-membres-f704b4553a5748d39b4490699105e9fb", "_blank") },
							// 					target: '_blank',
							// 				}, {
							// 					name: "Créer une formation présentielle",
							// 					onClick: () => { window.open("https://app.tango.us/app/workflow/Cr-er-une-habilitation-en-pr-sentiel-ff11ccef52a843e3afd4d2e5fb1b23ae") },
							// 					target: '_blank',
							// 				}]
							// 			}
							// 		]}
							// 	/>
							// </EuiPopover >
							// ,
							// <EuiHeaderSectionItemButton onClick={() => {
							// 	// navigate to http://biologie-habilitation.zendesk.com/

							// 	window.open("https://biologie-habilitation.zendesk.com/", "_blank")
							// }}>
							// 	<EuiHeaderLink iconType="documentation">
							// 		{" "}
							// 		Aide
							// 	</EuiHeaderLink>
							// </EuiHeaderSectionItemButton>,
							<Link to="me">
								<EuiHeaderSectionItemButton>
									<EuiHeaderLink iconType="user"> Mon profil</EuiHeaderLink>
								</EuiHeaderSectionItemButton>
							</Link>,
							<Link to="notifications">
								<EuiHeaderSectionItemButton>
									<EuiHeaderLink iconType="bell">
										{notif_count > 0 && <EuiNotificationBadge style={{ marginTop: -1 }}>{notif_count}</EuiNotificationBadge>} Notifications
									</EuiHeaderLink>
								</EuiHeaderSectionItemButton>
							</Link>,
							<EuiHeaderSectionItemButton
								onClick={() => {
									logout();
								}}
							>
								<EuiHeaderLink>Déconnexion</EuiHeaderLink>
							</EuiHeaderSectionItemButton>,
						],
					},
				]}
			/>

			{userContext?.loggedAs && (
				<>
					<EuiCallOut title="Connexion en tant qu'opérateur" color="warning" iconType="user">
						<EuiFlexGroup>
							<EuiFlexItem>
								<p>
									Vous êtes actuellement connecté(e) en tant {userContext.loggedAs.firstname} {userContext.loggedAs.lastname}{" "}
								</p>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiButton
									color="warning"
									onClick={() => {
										sessionStorage.removeItem("logged_as");
										setUserContext((oldValues) => ({ ...oldValues, loggedAs: null }));

										window.location.pathname = "/";
										window.location.reload();
									}}
								>
									Revenir à mon compte
								</EuiButton>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiCallOut>
				</>
			)}

			<Router className="h-full" primary={false}>
				<Notifications path="/notifications/*" refetch_notifs={fetch_notif_count} />
				<Preview path="/preview/:formation_instance_id/*" />
				<Dashboard path="/*" />
			</Router>
		</>
	);
}
