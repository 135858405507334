import { EuiButtonEmpty, EuiFilePicker, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiProgress, EuiSplitPanel, EuiTitle, EuiErrorBoundary, EuiButton, EuiLoadingContent, EuiAspectRatio, EuiText } from '@elastic/eui'
import axios from 'axios'
import isHotkey from 'is-hotkey'
import { useCallback, useEffect, useState } from 'react'
import {
	createEditor, Editor, Element as SlateElement, Transforms, Node
} from 'slate'
import { withHistory } from 'slate-history'
import { Editable, ReactEditor, Slate, useSelected, useSlate, useSlateStatic, withReact, } from 'slate-react'
import { API_PATH } from '../effects/useApi'
import isUrl from 'is-url'

import { Button, Icon, Toolbar } from './slate_components'
import { css } from '@emotion/css'

const HOTKEYS = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline',
	'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']


const withInlines = editor => {
	const { insertData, insertText, isInline } = editor

	editor.isInline = element =>
		['link', 'button'].includes(element.type) || isInline(element)

	editor.insertText = text => {
		if (text && isUrl(text)) {
			wrapLink(editor, text)
		} else {
			insertText(text)
		}
	}

	editor.insertData = data => {
		const text = data.getData('text/plain')

		if (text && isUrl(text)) {
			wrapLink(editor, text)
		} else {
			insertData(data)
		}
	}

	return editor
}

const wrapLink = (editor, url: string) => {
	if (isLinkActive(editor)) {
		unwrapLink(editor)
	}

	const { selection } = editor
	// @ts-ignore
	// const isCollapsed = selection && (new Range()).isCollapsed(selection)
	const link = {
		type: 'link',
		url,
		// children: isCollapsed ? [{ text: url }] : [],
		children: []
	}

	// if (isCollapsed) {
	// 	Transforms.insertNodes(editor, link)
	// } else {
	Transforms.wrapNodes(editor, link, { split: true })
	Transforms.collapse(editor, { edge: 'end' })
	// }
}

const InlineChromiumBugfix = () => (
	<span
		contentEditable={false}
		className={css`
		font-size: 0;
	  `}
	>
		${String.fromCodePoint(160) /* Non-breaking space */}
	</span>
)

const LinkComponent = ({ attributes, children, element, editor }) => {
	const selected = useSelected()

	const isReadOnly = ReactEditor.isReadOnly(editor)

	const url = (element.url.indexOf('://') === -1) ? 'https://' + element.url : element.url;

	if (isReadOnly) {
		return (
			<a {...attributes} href={url} target="_blank">
				{children}
			</a>
		)
	}

	return (
		<a
			{...attributes}
			href={url}
			className={
				selected
					? css`
				box-shadow: 0 0 0 3px #ddd;
			  `
					: ''
			}
			style={{ textDecoration: 'underline' }}
			title={url}
		>
			<InlineChromiumBugfix />
			{children}
			<InlineChromiumBugfix />
		</a>
	)
}

const Text = props => {
	const { attributes, children, leaf } = props
	return (
		<span
			// The following is a workaround for a Chromium bug where,
			// if you have an inline at the end of a block,
			// clicking the end of a block puts the cursor inside the inline
			// instead of inside the final {text: ''} node
			// https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
			className={
				leaf.text === ''
					? css`
				padding-left: 0.1px;
			  `
					: null
			}
			{...attributes}
		>
			{children}
		</span>
	)
}

const insertLink = (editor, url) => {
	if (editor.selection) {
		wrapLink(editor, url)
	}
}

const isLinkActive = editor => {
	// @ts-ignore
	const [link] = Editor.nodes(editor, {
		// @ts-ignore
		match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
	})
	return !!link
}

const isButtonActive = editor => {
	// @ts-ignore
	const [button] = Editor.nodes(editor, {
		// @ts-ignore
		match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'button',
	})
	return !!button
}

const unwrapLink = editor => {
	Transforms.unwrapNodes(editor, {
		// @ts-ignore
		match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
	})
}

const unwrapButton = editor => {
	Transforms.unwrapNodes(editor, {
		// @ts-ignore
		match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'button',
	})
}

const AddLinkButton = () => {
	const editor = useSlate()
	return (
		<Button
			active={isLinkActive(editor)}
			onMouseDown={event => {
				event.preventDefault()
				const url = window.prompt('Enter the URL of the link:')
				if (!url) return
				insertLink(editor, url)
			}}
		>
			<Icon>link</Icon>
		</Button>
	)
}

const RemoveLinkButton = () => {
	const editor = useSlate()

	return (
		<Button
			active={isLinkActive(editor)}
			onMouseDown={event => {
				if (isLinkActive(editor)) {
					unwrapLink(editor)
				}
			}}
		>
			<Icon>link_off</Icon>
		</Button>
	)
}


const withLayout = (editor: Editor) => {
	const { normalizeNode, insertBreak } = editor

	editor.normalizeNode = ([node, path]) => {
		if (path.length === 0) {
			if (editor.children.length < 1) {
				const title = {
					type: 'title',
					children: [{ text: 'Nouvelle page' }],
				}
				Transforms.insertNodes(editor, title, { at: path.concat(0) })
			}

			if (editor.children.length < 2) {
				const paragraph = {
					type: 'paragraph',
					children: [{ text: '' }],
				}
				Transforms.insertNodes(editor, paragraph, { at: path.concat(1) })
			}

			// @ts-ignore
			if (editor.children.length > 0 && editor.children[editor.children.length - 1].type !== "paragraph") {



				const paragraph = {
					type: 'paragraph',
					children: [{ text: '' }],
				}
				Transforms.insertNodes(editor, paragraph, { at: path.concat(editor.children.length) })
			}



			// @ts-ignore
			// for (const [child, childPath] of Node.children(editor, path)) {
			// 	let type: string
			// 	const slateIndex = childPath[0]
			// 	const enforceType = type => {
			// 		// @ts-ignore
			// 		if (SlateElement.isElement(child) && child.type !== type) {
			// 			// @ts-ignore
			// 			const newProperties: Partial<SlateElement> = { type }
			// 			Transforms.setNodes<SlateElement>(editor, newProperties, {
			// 				at: childPath,
			// 			})
			// 		}
			// 	}

			// 	switch (slateIndex) {
			// 		case 0:
			// 			type = 'title'
			// 			enforceType(type)
			// 			break
			// 		case 1:
			// 			type = 'paragraph'
			// 			enforceType(type)
			// 		default:
			// 			break
			// 	}
			// }
		}

		return normalizeNode([node, path])
	}



	return editor
}

const RichText = ({ read_only, value, onChange, page_id }: { read_only?: boolean, value: string | Array<any>, onChange: (string) => void, page_id?: string }) => {
	const renderElement = useCallback(props => <Element {...props} />, [page_id])
	const renderLeaf = useCallback(props => <Leaf {...props} />, [page_id])

	const [editor, setEditor] = useState<Editor | null>(null)

	useEffect(() => {
		setEditor(withInlines(withLayout(withHistory(withReact(createEditor())))))
	}, [page_id])


	if (!editor) return <EuiPanel hasBorder>
		<EuiLoadingContent lines={3} />
	</EuiPanel>

	let _value = [{ "type": "paragraph", "align": "left", "children": [{ "text": "" }] }]

	try {
		_value = JSON.parse(value as string)
	} catch (e) {
	}

	return (<EuiPanel hasBorder>
		{/* @ts-ignore */}
		<Slate editor={editor} value={_value} onChange={(v) => onChange(JSON.stringify(v))} >
			{!read_only && <Toolbar>
				<MarkButton format="bold" icon="format_bold" />
				<MarkButton format="italic" icon="format_italic" />
				<MarkButton format="underline" icon="format_underlined" />
				<BlockButton format="heading-one" icon="looks_one" />
				<BlockButton format="heading-two" icon="looks_two" />
				<BlockButton format="heading-three" icon="looks_3" />
				<BlockButton format="numbered-list" icon="format_list_numbered" />
				<BlockButton format="bulleted-list" icon="format_list_bulleted" />
				<BlockButton format="left" icon="format_align_left" />
				<BlockButton format="center" icon="format_align_center" />
				<BlockButton format="right" icon="format_align_right" />
				<BlockButton format="justify" icon="format_align_justify" />
				<BlockButton format="image" icon="image" />
				<BlockButton format="pdf" icon="picture_as_pdf" />
				<BlockButton format="video" icon="video_file" />

				<AddLinkButton />

				<RemoveLinkButton />
			</Toolbar>
			}

			<Editable
				renderElement={renderElement}
				renderLeaf={renderLeaf}
				placeholder={read_only ? "Cette page est vide" : "Commencez à écrire..."}
				spellCheck
				readOnly={read_only || false}
				autoFocus
				onKeyDown={event => {
					for (const hotkey in HOTKEYS) {
						if (isHotkey(hotkey, event as any)) {
							event.preventDefault()
							const mark = HOTKEYS[hotkey]
							toggleMark(editor, mark)
						}
					}

					// if (event.key === 'Enter') {
					// 	const selectedElement = Node.descendant(editor, editor.selection.anchor.path.slice(0, -1));

					// 	// Replace 'title' with the type of the element which you wish to "break out" from
					// 	// @ts-ignore
					// 	if (selectedElement.type === 'heading-one') {
					// 		event.preventDefault();
					// 		const selectedLeaf = Node.descendant(editor, editor.selection.anchor.path);

					// 		// @ts-ignore
					// 		if (selectedLeaf.text.length === editor.selection.anchor.offset) {
					// 			Transforms.insertNodes(editor, {
					// 				type: 'paragraph',
					// 				// @ts-ignore
					// 				children: [{ text: '', marks: [] }],
					// 			});
					// 		}
					// 		else {
					// 			Transforms.splitNodes(editor);
					// 			// @ts-ignore
					// 			Transforms.setNodes(editor, { type: 'paragraph' });
					// 		}
					// 	}
					// }
				}}
			/>
		</Slate>
	</EuiPanel>
	)
}

const toggleBlock = (editor, format) => {
	const isActive = isBlockActive(
		editor,
		format,
		TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
	)
	const isList = LIST_TYPES.includes(format)

	Transforms.unwrapNodes(editor, {
		// @ts-ignore
		match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type) && !TEXT_ALIGN_TYPES.includes(format),
		split: true,
	})
	let newProperties: Partial<SlateElement>
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			// @ts-ignore
			align: isActive ? undefined : format,
		}
	} else {
		newProperties = {
			// @ts-ignore
			type: isActive ? 'paragraph' : isList ? 'list-item' : format,
		}
	}
	Transforms.setNodes<SlateElement>(editor, newProperties)

	if (!isActive && isList) {
		const block = { type: format, children: [] }
		Transforms.wrapNodes(editor, block)
	}
}

const toggleMark = (editor, format) => {
	const isActive = isMarkActive(editor, format)

	if (isActive) {
		Editor.removeMark(editor, format)
	} else {
		Editor.addMark(editor, format, true)
	}
}

const isBlockActive = (editor, format, blockType = 'type') => {
	const { selection } = editor
	if (!selection) return false

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: n =>
				!Editor.isEditor(n) &&
				SlateElement.isElement(n) &&
				n[blockType] === format,
		})
	)

	return !!match
}

const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor)
	return marks ? marks[format] === true : false
}

const FileBlock = ({ type, attributes, children, element, editor }) => {

	const [media_id, setMediaId] = useState<string | null>(null)
	const [uploading, setUploading] = useState<boolean>(false)
	const [uploadProgress, setUploadProgress] = useState<number>(0)
	const [file_size, setFileSize] = useState<number>(0)

	const [uploadedFile, setUploadedFile] = useState<File | null>(null)

	const isReadOnly = ReactEditor.isReadOnly(editor)

	const onChange = (files: FileList) => {

		const formData = new FormData()

		setUploading(true)

		for (let i = 0; i < files.length; i++) { formData.append('file', files[i]) }

		const config = {
			onUploadProgress: progressEvent => { setUploadProgress(progressEvent.loaded); setFileSize(progressEvent.total) },
			headers: { 'content-type': 'multipart/form-data' }
		}

		axios.post(API_PATH + "upload", formData, config)
			.then(({ data }: any) => {
				setMediaId(data.media_id);
				setUploadedFile(data.file)

				// @ts-ignore
				const path = ReactEditor.findPath(editor, element)
				// @ts-ignore
				const newProperties: Partial<SlateElement> = { media: data.media_id, uploaded: true }
				Transforms.setNodes<SlateElement>(editor, newProperties, {
					at: path,
				})

				setUploading(false)
			})
			.catch((error) => { console.error(error) });

	}

	if (type === "pdf" && element.uploaded) {
		return <div {...attributes} contentEditable={false}>

			<EuiSplitPanel.Outer style={{ marginBottom: 10 }}>
				{isReadOnly && <EuiSplitPanel.Inner style={{ textAlign: 'center' }}>
					<EuiButton color="text" fullWidth href={API_PATH + "videos/" + element.media} target="_blank" download>Afficher le fichier PDF en plein écran</EuiButton>
				</EuiSplitPanel.Inner>}

				<EuiSplitPanel.Inner>

					{/* <iframe src={`https://docs.google.com/viewer?url=${encodeURIComponent(API_PATH + "videos/" + element.media)}&embedded=true`} width="800px" height="800"></iframe>*/}

					<object data={API_PATH + "videos/" + element.media} type="application/pdf" width="100%" height="100%" style={{ minHeight: 600, objectFit: "contain" }}>
						<embed src={API_PATH + "videos/" + element.media} width="100%" height="600px" />
					</object>



					{/* <embed src={API_PATH + "videos/" + element.media} style={{ maxWidth: 800, objectFit: "contain" }} type="application/pdf" /> */}


				</EuiSplitPanel.Inner>

				{!isReadOnly && <EuiSplitPanel.Inner color="subdued" paddingSize='xs'>
					<EuiFlexGroup>
						<EuiFlexItem></EuiFlexItem>

						<EuiFlexItem grow={false}>
							<EuiButtonEmpty
								iconType="trash"
								color="danger"
								onClick={() => {
									// @ts-ignore
									const path = ReactEditor.findPath(editor, element)
									// @ts-ignore
									Transforms.removeNodes(editor, { at: path })
								}}
							>
								Supprimer
							</EuiButtonEmpty>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiSplitPanel.Inner>}

			</EuiSplitPanel.Outer>
		</div>
	}

	if (type === "video" && element.uploaded) {
		return <div {...attributes} contentEditable={false}>
			<EuiSplitPanel.Outer style={{ marginBottom: 10 }}>
				<EuiSplitPanel.Inner>
					<EuiAspectRatio width={16} height={9}>
						<video controls style={{ maxWidth: 800, objectFit: "contain" }}>
							<source src={API_PATH + "mp4videos/" + element.media + ".mp4"} type="video/mp4" />
							<EuiText><a href={API_PATH + "videos/" + element.media}>Télécharger la vidéo</a></EuiText>
						</video>
					</EuiAspectRatio>
				</EuiSplitPanel.Inner>

				{!isReadOnly && <EuiSplitPanel.Inner color="subdued" paddingSize='xs'>
					<EuiFlexGroup>
						<EuiFlexItem></EuiFlexItem>

						<EuiFlexItem grow={false}>
							<EuiButtonEmpty
								iconType="trash"
								color="danger"
								onClick={() => {
									// @ts-ignore
									const path = ReactEditor.findPath(editor, element)
									// @ts-ignore
									Transforms.removeNodes(editor, { at: path })
								}}
							>
								Supprimer
							</EuiButtonEmpty>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiSplitPanel.Inner>}

			</EuiSplitPanel.Outer>
		</div>
	}

	if (type === "image" && element.uploaded) {
		return <div {...attributes} contentEditable={false}>
			<EuiSplitPanel.Outer style={{ marginBottom: 10 }}>
				<EuiSplitPanel.Inner>

					<img src={API_PATH + "videos/" + element.media} style={{ maxWidth: '95%' }} />

				</EuiSplitPanel.Inner>

				{!isReadOnly && <EuiSplitPanel.Inner color="subdued" paddingSize='xs'>
					<EuiFlexGroup>
						<EuiFlexItem></EuiFlexItem>

						<EuiFlexItem grow={false}>
							<EuiButtonEmpty
								iconType="trash"
								color="danger"
								onClick={() => {
									// @ts-ignore
									const path = ReactEditor.findPath(editor, element)
									// @ts-ignore
									Transforms.removeNodes(editor, { at: path })
								}}
							>
								Supprimer
							</EuiButtonEmpty>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiSplitPanel.Inner>}

			</EuiSplitPanel.Outer>
		</div >
	}

	let prompt = ""

	switch (type) {
		case "image":
			prompt = "Choisissez une image"
			break;
		case "video":
			prompt = "Choisissez une vidéo"
			break;
		case "pdf":
			prompt = "Choisissez un pdf"
			break;
	}


	return <div {...attributes} contentEditable={false}>
		{uploading && <EuiProgress size="xs" color="primary" value={uploadProgress} max={file_size} />}
		<EuiFilePicker
			id={"file"}
			multiple
			initialPromptText={prompt}
			onChange={onChange}
			fullWidth
		/>
	</div>
}

const Element = ({ attributes, children, element }) => {

	const editor = useSlateStatic()

	const style = { textAlign: element.align }
	switch (element.type) {
		case 'block-quote':
			return (
				<blockquote style={style} {...attributes}>
					{children}
				</blockquote>
			)
		case 'bulleted-list':
			return (
				<ul style={style} {...attributes}>
					{children}
				</ul>
			)
		case 'heading-one':
			return (<>
				<EuiTitle size="l" {...attributes}>
					<h1 style={style}>
						{children}
					</h1>
				</EuiTitle>
			</>)
		case 'heading-two':
			return (<>
				<EuiTitle size="m" {...attributes}>
					<h2 style={style}>
						{children}
					</h2>
				</EuiTitle>
			</>)

		case 'heading-three':
			return (<>
				<EuiTitle size="s" {...attributes}>
					<h3 style={style}>
						{children}
					</h3>
				</EuiTitle>
			</>)
		case 'list-item':
			return (
				<li style={style} {...attributes}>
					{children}
				</li>
			)
		case 'numbered-list':
			return (
				<ol style={style} {...attributes}>
					{children}
				</ol>
			)

		case 'pdf': {
			return <FileBlock type="pdf" attributes={attributes} children={children} element={element} editor={editor} />
		}

		case 'video': {
			return <FileBlock type="video" attributes={attributes} children={children} element={element} editor={editor} />
		}

		case 'image': {
			return <FileBlock type="image" attributes={attributes} children={children} element={element} editor={editor} />
		}

		case 'link':
			return <LinkComponent attributes={attributes} children={children} element={element} editor={editor} />

		default:
			return (
				<p style={style} {...attributes}>
					{children}
				</p>
			)
	}
}

const Leaf = ({ attributes, children, leaf }) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>
	}

	if (leaf.code) {
		children = <code>{children}</code>
	}

	if (leaf.italic) {
		children = <em>{children}</em>
	}

	if (leaf.underline) {
		children = <u>{children}</u>
	}

	return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
	const editor = useSlate()
	return (
		<Button
			active={isBlockActive(
				editor,
				format,
				TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
			)}
			onMouseDown={event => {
				event.preventDefault()
				toggleBlock(editor, format)
			}}
		>
			<Icon>{icon}</Icon>
		</Button>
	)
}

const MarkButton = ({ format, icon }) => {
	const editor = useSlate()
	return (
		<Button
			active={isMarkActive(editor, format)}
			onMouseDown={event => {
				event.preventDefault()
				toggleMark(editor, format)
			}}
		>
			<Icon>{icon}</Icon>
		</Button>
	)
}

export function serialize(value: any) {

	if (value && Array.isArray(value)) {
		return value.map(n => serialize(n)).join('')
	}

	if (!value.type) return `${value.text}`

	switch (value.type) {
		case 'paragraph':
			return `<p>${serialize(value.children)}</p>`
		case 'block-quote':
			return `<blockquote>${serialize(value.children)}</blockquote>`
		case 'bulleted-list':
			return `<ul>${serialize(value.children)}</ul>`
		case 'heading-one':
			return `<h1>${serialize(value.children)}</h1>`
		case 'heading-two':
			return `<h2>${serialize(value.children)}</h2>`
		case 'heading-three':
			return `<h3>${serialize(value.children)}</h3>`
		case 'list-item':
			return `<li>${serialize(value.children)}</li>`
		case 'numbered-list':
			return `<ol>${serialize(value.children)}</ol>`
		case 'link':
			const link = (value.url.indexOf('://') === -1) ? 'http://' + value.url : value.url;
			return `<a href="${link}">${serialize(value.children)}</a>`
		case 'image': {
			// return `<img src="${value.url}" alt="${value.alt}" />`
			return `<a href="${API_PATH + "videos/" + value.media}">Voir l'image (${API_PATH + "videos/" + value.media})</a>`
		}
		case 'video':{
			// return `<video src="${value.url}" controls></video>`
			return `<a href="${API_PATH + "videos/" + value.media}">Télécharger la vidéo (${API_PATH + "videos/" + value.media})</a>`
		}
		case 'pdf':
		// 	return `<object data="${value.url}" type="application/pdf" width="100%" height="1000px">
		// 	<embed src="${value.url}" type="application/pdf" />
		// </object>`
		return `<a href="${API_PATH + "videos/" + value.media}">Télécharger le fichier PDF (${API_PATH + "videos/" + value.media})</a>`
		default:
			return serialize(value.children)
	}
}

export default RichText