import { EuiButton, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiPanel, EuiSpacer, EuiText } from "@elastic/eui";
import { navigate, Router, useMatch } from "@reach/router";
import { useEffect, useState } from "react";
import RichText from "../../components/RichText";
import { useApi } from "../../effects/useApi";
import { CourseModule, CourseModuleInstance, FormationVersionInstance, Page } from "../../types";

export default function CourseModulePage({ module_instance, formation_version_instance, formation_instance_id, refetch_formation, navigate }: { module_instance: CourseModuleInstance, formation_version_instance: FormationVersionInstance, formation_instance_id?: string, refetch_formation?: () => void, navigate?: (string) => void }) {

	const module = module_instance.module as unknown as CourseModule

	const match = useMatch("/formation_instances/:formation_instance_id/:module_instance_id/:page_id") as unknown as { page_id: string }

	const current_page_index = (module.pages as unknown as string[]).indexOf(match?.page_id) + 1

	const pages_length = module.pages.length

	const last_page_read = (module_instance.page_read && module_instance.page_read.length > 0) ? module_instance.page_read[module_instance.page_read.length - 1] : undefined

	return <>

		{(module_instance.is_validated) && <><EuiCallOut color="success" iconType="check" size="m">
			<EuiFlexGroup gutterSize="none" style={{ placeItems: "center" }}>
				<EuiFlexItem><EuiText size="m" color="success">Ce module est complet, vous pouvez accéder au suivant.</EuiText></EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton color="success" onClick={() => navigate('/formation_instances/' + formation_version_instance._id + '/' + formation_version_instance.module_instances[1]._id as unknown as string)}>Module suivant</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>
		</EuiCallOut><EuiSpacer size="l" /></>}



		<Router primary={false}>
			<DisplayPage path=":page_id" formation_instance_id={formation_instance_id} module_instance_id={module_instance._id as unknown as string} refetch_formation={refetch_formation} />
			<Empty path="/" page_id={last_page_read || (module_instance.module as unknown as CourseModule).pages[0] as unknown as string} navigate={navigate} />
		</Router>

		<EuiSpacer size="l" />

		<EuiPanel paddingSize="s" >
			<EuiFlexGroup gutterSize="s">

				<EuiFlexItem grow={false}>
					<EuiButton
						size="s"
						color="text"
						iconSide="left"
						iconType="arrowLeft"
						isDisabled={current_page_index === 1}
						onClick={() => { navigate(module.pages[current_page_index - 2] as unknown as string || ""); window.location.reload() }}
					>
						Précédent
					</EuiButton>
				</EuiFlexItem>

				<EuiFlexItem style={{ textAlign: "center", placeContent: "center" }}>
					{current_page_index} / {pages_length}
				</EuiFlexItem>

				<EuiFlexItem grow={false}>
					{current_page_index === pages_length ?
						<EuiButton size="s" color="success" onClick={() => navigate('/formation_instances/' + formation_version_instance._id + '/' + formation_version_instance.module_instances[1]._id as unknown as string)}>
							Module suivant
						</EuiButton> :
						<EuiButton
							size="s" color="text" iconSide="right" iconType="arrowRight"
							isDisabled={current_page_index === pages_length}
							onClick={() => { navigate(module.pages[current_page_index] as unknown as string || ""); window.location.reload() }}
						>
							Suivant
						</EuiButton>
					}
				</EuiFlexItem>

			</EuiFlexGroup>

		</EuiPanel>

	</>
}

function DisplayPage({ path, page_id, formation_instance_id, module_instance_id, refetch_formation }: { path: string, page_id?: string, formation_instance_id?: string, module_instance_id?: string, refetch_formation?: () => void }) {

	const [page, setPage] = useState<Page | undefined>(undefined)

	const [fetch_page] = useApi('GET', `formation_instances/${formation_instance_id}/module_instances/${module_instance_id}/pages/${page_id}`, async (err, page) => {
		setPage(page)
		refetch_formation()
	})

	useEffect(() => {
		fetch_page()
	}, [page_id])

	if (!page) return <EuiLoadingContent lines={6} />

	return <div>
		<RichText
			read_only
			value={page.components}
			onChange={(value) => { }}
			page_id={"course content"}
		/>
	</div>
}

function Empty({ path, page_id, navigate }: { path: string, page_id: string, navigate?: (path: string) => void }) {

	useEffect(() => {
		if (page_id) navigate(page_id)
	}, [page_id])

	return <>
	</>
}