import { EuiCallOut, EuiComboBox, EuiFieldPassword, EuiLoadingContent, EuiPageTemplate, EuiSelect, EuiSpacer } from '@elastic/eui';
import { useEffect, useState } from 'react';
import { useApi } from '../effects/useApi';
import { Laboratory, User } from '../types';

import {
	EuiDescribedFormGroup, EuiFormRow, EuiFieldText, EuiPanel, EuiButton
} from '@elastic/eui';

export default function Me({ path, laboratory_id, formation_id, navigate }: { path: string, laboratory_id?: string, formation_id?: string, navigate?: any }) {


	const [me, setMe] = useState<User | null>(null)

	const [roles, setRoles] = useState<{ member_of: Laboratory[] } | undefined>(undefined)
	const [fetch] = useApi('GET', 'users/roles', async (err, roles) => {
		setLaboratory(roles.member_of[0])
	})

	const [fetch_me] = useApi('GET', 'users/me', async (err, me) => {
		setMe(me)

		setFirstname(me.firstname)
		setLastname(me.lastname)
		setEmail(me.email)
		setPhone(me.phone)
		setJobTitle(me.job_title)
		setRppsNumber(me.rpps_number)
		setUsername(me.username)
		// setPlace(me.place)
		// setPost(me.post)
		setPosts(me.posts)
		setPlaces(me.places)

		// setLaboratory(me.member_of[0])
	})

	const [laboratory, setLaboratory] = useState<Laboratory | null>(null)

	const [update_profile, updating] = useApi('POST', 'users/me', async (err, me) => {
		if (err) {
			console.error(err)
			return
		}
		await fetch_me()
	})

	useEffect(() => {
		fetch()
		fetch_me()
	}, [])

	const [firstname, setFirstname] = useState<string>('')
	const [lastname, setLastname] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [phone, setPhone] = useState<string>('')
	const [username, setUsername] = useState<string>('')

	// const [place, setPlace] = useState<string>('')
	// const [post, setPost] = useState<string>('')

	const [places, setPlaces] = useState<string[]>([])
	const [posts, setPosts] = useState<string[]>([])

	const [job_title, setJobTitle] = useState<string>('')

	const [rpps_number, setRppsNumber] = useState<string>('')

	const [password, setPassword] = useState<string>('')
	const [password_confirm, setPasswordConfirm] = useState<string>('')

	const [password_changed, setPasswordChanged] = useState<boolean>(false)

	const [update_password, updating_password] = useApi('POST', 'users/password', async (err, me) => {
		if (err) {
			console.error(err)
			return
		}
		setPassword('')
		setPasswordConfirm('')

		setPasswordChanged(true)
	})


	if (!me || !laboratory) return <EuiPageTemplate>
		<EuiPageTemplate.Header description={<EuiLoadingContent lines={1} />}>
		</EuiPageTemplate.Header>
		<EuiPageTemplate.Section>
			<EuiLoadingContent lines={5} />

		</EuiPageTemplate.Section>
	</EuiPageTemplate>

	const is_referent = me.referent_of?.length > 0

	return <EuiPageTemplate><form autoComplete="off">


		<EuiPageTemplate.Header
			pageTitle={`${me.firstname} ${me.lastname}`}
			rightSideItems={[
				is_referent ? <EuiButton fill isLoading={updating} onClick={() => {
					update_profile({
						firstname,
						lastname,
						email,
						phone,
						job_title,
						rpps_number,
						// place,
						// post
						posts,
						places
					})
				}}>Sauvegarder</EuiButton> : <></>,
			]}
		/>

		<EuiPageTemplate.Section>

			<EuiPanel hasBorder>
				<EuiDescribedFormGroup
					fullWidth
					title={<h3>Coordonnées</h3>}
					description={<></>}
				>
					<EuiFormRow
						fullWidth
						label="Prénom"
					>
						<EuiFieldText disabled={!is_referent} fullWidth required value={firstname} onChange={(e) => { setFirstname(e.target.value) }} />
					</EuiFormRow>

					<EuiFormRow
						fullWidth
						label="Nom"
					>
						<EuiFieldText disabled={!is_referent} fullWidth required value={lastname} onChange={(e) => { setLastname(e.target.value) }} />
					</EuiFormRow>

					<EuiFormRow
						fullWidth
						label="Nom d'utilisateur"
					>
						<EuiFieldText disabled fullWidth value={username} onChange={(e) => { }} />
					</EuiFormRow>

					<EuiFormRow
						fullWidth
						label="Téléphone"
					>
						<EuiFieldText disabled={!is_referent} fullWidth value={phone} onChange={(e) => { setPhone(e.target.value) }} />
					</EuiFormRow>

					<EuiFormRow
						fullWidth
						label="Email"
					>
						<EuiFieldText disabled={!is_referent} fullWidth value={email} onChange={(e) => { setEmail(e.target.value) }} />
					</EuiFormRow>

					<EuiFormRow
						fullWidth
						label="Champ personnalisé 1"
					>
						<EuiFieldText disabled={!is_referent} fullWidth value={job_title} onChange={(e) => { setJobTitle(e.target.value) }} />
					</EuiFormRow>

					<EuiFormRow
						fullWidth
						label="Champ personnalisé 2"
					>
						<EuiFieldText disabled={!is_referent} fullWidth value={rpps_number} onChange={(e) => { setRppsNumber(e.target.value) }} />
					</EuiFormRow>
				</EuiDescribedFormGroup>

			</EuiPanel>

			<EuiSpacer size="l" />

			<EuiPanel hasBorder>

				<EuiDescribedFormGroup
					fullWidth
					title={<h3>{laboratory?.name}</h3>}
					description={<></>}
				>
					<EuiFormRow
						fullWidth
						label="Lieux d'exercice"
					>
						<EuiComboBox
							placeholder="Lieux d'exercice"
							options={laboratory.possible_places?.map((place) => { return { label: place, value: place } })}
							onChange={(values) => { setPlaces(values.map((value) => value.value)) }}
							selectedOptions={(places || []).map((place) => { return { label: place, value: place } })}
							isClearable={false}
							fullWidth
							isDisabled={!is_referent}
						/>
					</EuiFormRow>

					<EuiFormRow
						fullWidth
						label="Postes"
					>
						<EuiComboBox
							placeholder="Postes"
							options={laboratory.possible_posts?.map((post) => { return { label: post, value: post } })}
							onChange={(values) => { setPosts(values.map((value) => value.value)) }}
							selectedOptions={(posts || []).map((post) => { return { label: post, value: post } })}
							isClearable={false}
							fullWidth
							isDisabled={!is_referent}
						/>
					</EuiFormRow>


				</EuiDescribedFormGroup>


			</EuiPanel>

			<EuiSpacer size="l" />

			{me.email ? <EuiPanel hasBorder>


				{password_changed && <><EuiCallOut
					title="Le mot de passe a été modifié"
					color="success"
					iconType="check"
				>
					{/* Le mot de passe a été modifié */}
				</EuiCallOut><EuiSpacer /></>}


				<EuiDescribedFormGroup
					fullWidth
					title={<h3>Modification du mot de passe</h3>}
					description={<></>}
				>
					<EuiFormRow
						fullWidth
						label="Nouveau mot de passe"
					>
						<EuiFieldPassword
							fullWidth
							value={password}
							onChange={(e) => { setPassword(e.target.value) }}
							disabled={!me.email}
						/>
					</EuiFormRow>

					<EuiFormRow
						fullWidth
						label="Confirmation du nouveau mot de passe"
					>
						<EuiFieldPassword
							fullWidth
							value={password_confirm}
							onChange={(e) => { setPasswordConfirm(e.target.value) }}
							isInvalid={password !== password_confirm}
							disabled={!me.email}
						/>

					</EuiFormRow>


					<EuiFormRow
						fullWidth
					// label="Modifier mon mot de passe"
					>
						<EuiButton
							fullWidth
							onClick={() => { update_password({ password }) }}
							isDisabled={(password !== password_confirm && password.length > 0) || !me.email}
						>
							Modifier mon mot de passe

						</EuiButton>

					</EuiFormRow>



				</EuiDescribedFormGroup>


			</EuiPanel> : <></>}


		</EuiPageTemplate.Section>
	</form></EuiPageTemplate>
}
