import { useEffect, useState } from "react";
import { useApi } from "../effects/useApi";
import { User } from "../types";
import { EuiCard, EuiIcon, EuiLoadingContent } from '@elastic/eui';

export default function UserSummary({ _id }: { _id: string }) {

	const [user, setUser] = useState<User | undefined>(undefined);

	const [fetch_user] = useApi('GET', `users/${_id}`, (err, user) => {
		if (!err) {
			setUser(user)
		}
	})

	useEffect(() => {
		fetch_user()
	}, [_id])

	if (user) {
		return <EuiCard
			layout="horizontal"
			icon={<EuiIcon size="m" type={'user'} />}
			title={user.firstname + " " + user.lastname}
			description={user.email}
			hasBorder
			onClick={() => { }}
			style={{marginTop: -10}}

		/>
	}

	// if (user) {
	// 	return <div>
	// 		<h1>{user.firstname} {user.lastname}</h1>
	// 		<p>{user.email}</p>
	// 	</div>
	// }

	return <>
		<EuiLoadingContent lines={3} />
	</>

}