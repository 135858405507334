import {
	EuiAccordion,
	EuiAspectRatio,
	EuiButton,
	EuiCallOut,
	EuiEmptyPrompt,
	EuiFlexGrid,
	EuiFlexGroup,
	EuiFlexItem,
	EuiHorizontalRule,
	EuiIcon,
	EuiImage,
	EuiLoadingContent,
	EuiPageHeader,
	EuiPageTemplate,
	EuiPanel,
	EuiSpacer,
	EuiSwitch,
	EuiText,
	EuiTextArea,
	EuiTitle,
} from "@elastic/eui";
import { useEffect, useState } from "react";
import RichText from "../components/RichText";
import { API_PATH, useApi } from "../effects/useApi";
import {
	CourseModule,
	CourseModuleInstance,
	Formation,
	FormationVersion,
	FormationVersionInstance,
	ModuleInstance,
	PracticalEvaluationModuleInstance,
	Quizz,
	QuizzPossibleAnswer,
	QuizzQuestion,
	TheoricEvaluationModuleInstance,
	User,
} from "../types";
import Comment from "../components/Comment";

export default function ({ path, formation_instance_id }: { path: string; formation_instance_id?: string }) {
	const [formation_instance, setFormationInstance] = useState<FormationVersionInstance | null>(null);

	const [fetch_formation_instance] = useApi("GET", `formation_instances/${formation_instance_id}/preview`, (err, formation_instance) => {
		if (err) {
			console.error(err);
			return;
		}
		setFormationInstance(formation_instance);
	});

	useEffect(() => {
		fetch_formation_instance();
	}, []);

	if (!formation_instance)
		return (
			<EuiPageTemplate>
				<EuiPageTemplate.Header description={<EuiLoadingContent lines={1} />}></EuiPageTemplate.Header>
				<EuiPageTemplate.Section>
					<EuiLoadingContent lines={5} />
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		);

	const formation_version = formation_instance.formation_version as unknown as FormationVersion;
	const formation = formation_instance.formation as unknown as Formation;

	const course_module = formation_version.modules[0];
	const theoric_eval_module = formation_version.modules[1];
	const practical_eval_module = formation_version.modules[2];

	const course_module_instance = formation_instance.module_instances[0] as unknown as CourseModuleInstance;
	const theoric_eval_module_instance = formation_instance.module_instances[1] as unknown as TheoricEvaluationModuleInstance;
	const practical_eval_module_instance = formation_instance.module_instances[2] as unknown as PracticalEvaluationModuleInstance;

	return (
		<EuiPageTemplate>
			<EuiPageTemplate.Header restrictWidth={"90%"}>
				<EuiPageHeader
					pageTitle={formation.header}
					restrictWidth={"90%"}
					rightSideItems={[
						<EuiButton href={formation_instance.certificate_url} color="text" target="_blank">
							Télécharger l'attestation d'évaluation au format PDF
						</EuiButton>,
					]}
				/>
			</EuiPageTemplate.Header>

			<EuiPageTemplate.Section restrictWidth={"90%"}>
				<>
					{/* {formation.is_live && ( */}
					<>
						<EuiTitle size="m">
							<h2>Conditions Générales d'Utilisation (CGU) de la Plateforme 360Skillvue</h2>
						</EuiTitle>

						<br />
						<EuiText style={{ textAlign: "right" }}>
							✅ le{" "}
							{new Date(formation_instance.allowed_to_start_on).toLocaleDateString("fr-FR", {
								timeZone: "Europe/Paris",
							})}{" "}
							: Vous avez accepté les CGU (
							<a href={formation_instance.allowed_to_start_attestation} target="_blank">
								Attestation
							</a>
							)
						</EuiText>
						<EuiSpacer size="l" />
					</>
					{/* )} */}

					<EuiTitle size="m">
						<h2>Documents</h2>
					</EuiTitle>

					<EuiHorizontalRule />

					{(!formation_instance.proofs || formation_instance.proofs.length === 0) && (
						<EuiEmptyPrompt title={<h2>Aucun document à fournir</h2>} body={<p>Vous n'avez pas besoin de fournir de document pour cette formation</p>} />
					)}

					{formation_instance.proofs && formation_instance.proofs.length > 0 && formation_instance.proofs.length === 0 && (
						<EuiEmptyPrompt title={<h2>Aucun document fourni</h2>} body={<p>Aucun document n'est nécessaire pour cette évaluation.</p>} />
					)}

					<EuiFlexGrid columns={4}>
						{formation_instance.proofs &&
							formation_instance.proofs.map((proof) => {
								return (
									<EuiFlexItem key={proof.id}>
										<EuiPanel hasBorder>
											{proof.media_id ? (
												<EuiImage
													size="l"
													alt={""}
													src={API_PATH + "videos/" + proof.media_id}
													caption={
														<p>
															<EuiText size="s">{proof.text}</EuiText>
														</p>
													}
												/>
											) : (
												<EuiPanel>
													<EuiCallOut title={"Document non fourni"} color="danger">
														<EuiText size="s">{proof.text}</EuiText>
													</EuiCallOut>
												</EuiPanel>
											)}
										</EuiPanel>{" "}
									</EuiFlexItem>
								);
							})}
					</EuiFlexGrid>
				</>

				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiTitle size="m">
							<h2>Module de présentation</h2>
						</EuiTitle>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiTitle>
							<h2>✅</h2>
						</EuiTitle>
					</EuiFlexItem>
				</EuiFlexGroup>

				<EuiHorizontalRule />

				<EuiFlexGrid columns={3}>
					{(course_module_instance.module as unknown as CourseModule).pages.map((page, i) => (
						<>
							<EuiFlexItem>
								<EuiPanel hasBorder>
									<EuiTitle size="xs">
										<h3>
											{course_module_instance.page_read.includes(page as unknown as string) ? "✅" : "❌"}
											   Page n°{i + 1}
										</h3>
									</EuiTitle>
								</EuiPanel>
							</EuiFlexItem>
							<EuiSpacer size="s" />
						</>
					))}
				</EuiFlexGrid>

				<EuiSpacer size="l" />

				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiTitle size="m">
							<h2>Module de questionnaire intermédiaire</h2>
						</EuiTitle>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiTitle>
							<h2>{theoric_eval_module_instance.is_validated ? "✅" : "❌"}</h2>
						</EuiTitle>
					</EuiFlexItem>
				</EuiFlexGroup>

				<EuiHorizontalRule />

				{theoric_eval_module_instance.previous_attempts.map((attempt, i) => (
					<Attempt attempt={attempt} i={i} theoric_eval_module_instance={theoric_eval_module_instance} />
				))}

				<EuiSpacer size="l" />

				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiTitle size="m">
							<h2>Module d'évaluation pratique</h2>
						</EuiTitle>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiTitle>
							<h2>{practical_eval_module_instance.is_awaiting_verification ? "🕒" : practical_eval_module_instance.is_validated ? "✅" : "❌"}</h2>
						</EuiTitle>
					</EuiFlexItem>
				</EuiFlexGroup>

				<EuiHorizontalRule />

				{practical_eval_module_instance.previous_attempts.map((attempt, i) => {
					return (
						<>
							<EuiPanel hasBorder>
								<EuiAccordion
									id={"practical_" + i}
									buttonContent={
										"Essai n°" +
										(i + 1) +
										" - le " +
										new Date(attempt.submitted_on).toLocaleDateString("fr-FR", {
											timeZone: "Europe/Paris",
										}) +
										" à " +
										new Date(attempt.submitted_on).toLocaleTimeString("fr-FR", {
											timeZone: "Europe/Paris",
										})
									}
								>
									{attempt.media_id ? (
										<EuiAspectRatio width={16} height={9}>
											<video
												controls
												style={{
													maxWidth: 800,
													objectFit: "contain",
												}}
											>
												<source src={API_PATH + "mp4videos/" + attempt.media_id + ".mp4"} type="video/mp4" />
												<EuiText>
													<a href={API_PATH + "mp4videos/" + attempt.media_id + ".mp4"}>Télécharger la vidéo</a>
												</EuiText>
											</video>
										</EuiAspectRatio>
									) : (
										<EuiEmptyPrompt title={<h2>Aucune vidéo</h2>} body={<p>Aucune vidéo n'a été téléversée pour cette évaluation pratique.</p>} />
									)}

									<EuiSpacer size="m" />

									{attempt.achievements?.map((achievement) => {
										return (
											<>
												<EuiPanel key={achievement._id} hasBorder>
													<EuiFlexGroup>
														<EuiFlexItem>
															<EuiTitle size="xs">
																<h3>{achievement.text}</h3>
															</EuiTitle>
														</EuiFlexItem>
														<EuiFlexItem
															grow={false}
															style={{
																placeContent: "center",
															}}
														>
															<EuiSwitch
																label={"Critère de compétence " + (achievement.is_validated ? "" : "non") + " validé"}
																checked={achievement.is_validated}
																disabled
																onChange={() => {}}
															/>
														</EuiFlexItem>
													</EuiFlexGroup>
												</EuiPanel>
												<EuiSpacer size="m" />
											</>
										);
									})}

									<EuiSpacer size="m" />

									<EuiPanel hasBorder>
										<EuiFlexGroup>
											<EuiFlexItem>
												<EuiTitle size="s">
													<h3>Commentaire du correcteur</h3>
												</EuiTitle>
											</EuiFlexItem>
										</EuiFlexGroup>

										<EuiSpacer size="m" />

										<Comment text={attempt.comment} />

										{/* <EuiTextArea
												fullWidth
												placeholder="Pas de commentaire du correcteur"
												value={attempt.comment}
												disabled
											/> */}
									</EuiPanel>

									{attempt.verificator ? (
										<>
											<EuiSpacer size="l" />

											<EuiPanel hasBorder>
												{attempt.is_awaiting_verification && (
													<>
														<EuiFlexGroup>
															<EuiFlexItem>
																<EuiTitle size="xs">
																	<h3> ⏳  En attente de validation par le vérificateur</h3>
																</EuiTitle>
															</EuiFlexItem>
														</EuiFlexGroup>
													</>
												)}

												{!attempt.is_awaiting_verification && attempt.validated_by_verificator && (
													<>
														<EuiFlexGroup>
															<EuiFlexItem>
																<EuiTitle size="xs">
																	<h3> ✅  Validé par le vérificateur</h3>
																</EuiTitle>
															</EuiFlexItem>
														</EuiFlexGroup>
													</>
												)}

												{!attempt.is_awaiting_verification && !attempt.validated_by_verificator && (
													<>
														<EuiFlexGroup>
															<EuiFlexItem>
																<EuiTitle size="xs">
																	<h3> ❌  Non validé par le vérificateur </h3>
																</EuiTitle>
															</EuiFlexItem>
														</EuiFlexGroup>
													</>
												)}
											</EuiPanel>
										</>
									) : (
										<></>
									)}
								</EuiAccordion>
							</EuiPanel>
							<EuiSpacer size="m" />
						</>
					);
				})}

				<EuiPanel hasBorder>
					<EuiAccordion
						id="new_attempt"
						buttonContent={
							"Essai n°" +
							(practical_eval_module_instance.previous_attempts.length + 1) +
							" - le " +
							new Date(practical_eval_module_instance.submitted_on).toLocaleDateString("fr-FR", {
								timeZone: "Europe/Paris",
							}) +
							" à " +
							new Date(practical_eval_module_instance.submitted_on).toLocaleTimeString("fr-FR", {
								timeZone: "Europe/Paris",
							})
						}
					>
						{practical_eval_module_instance.media_id ? (
							<EuiAspectRatio width={16} height={9}>
								<video
									controls
									style={{
										maxWidth: 800,
										objectFit: "contain",
									}}
								>
									<source src={API_PATH + "mp4videos/" + practical_eval_module_instance.media_id + ".mp4"} type="video/mp4" />
									<EuiText>
										<a href={API_PATH + "mp4videos/" + practical_eval_module_instance.media_id + ".mp4"}>Télécharger la vidéo</a>
									</EuiText>
								</video>
							</EuiAspectRatio>
						) : (
							<EuiEmptyPrompt title={<h2>Aucune vidéo</h2>} body={<p>Aucune vidéo n'a été téléversée pour cette évaluation pratique.</p>} />
						)}

						<EuiSpacer size="m" />

						{practical_eval_module_instance.achievements?.map((achievement) => {
							return (
								<>
									<EuiPanel key={achievement._id} hasBorder>
										<EuiFlexGroup>
											<EuiFlexItem>
												<EuiTitle size="xs">
													<h3>{achievement.text}</h3>
												</EuiTitle>
											</EuiFlexItem>
											<EuiFlexItem
												grow={false}
												style={{
													placeContent: "center",
												}}
											>
												<EuiSwitch
													label={"Critère de compétence " + (achievement.is_validated ? "" : "non") + " validé"}
													checked={achievement.is_validated}
													disabled
													onChange={() => {}}
												/>
											</EuiFlexItem>
										</EuiFlexGroup>
									</EuiPanel>
									<EuiSpacer size="m" />
								</>
							);
						})}

						<EuiSpacer size="m" />

						<EuiPanel hasBorder>
							<EuiFlexGroup>
								<EuiFlexItem>
									<EuiTitle size="s">
										<h3>Commentaire du correcteur</h3>
									</EuiTitle>
								</EuiFlexItem>
							</EuiFlexGroup>

							<EuiSpacer size="m" />

							<Comment text={practical_eval_module_instance.comment} />

							{/* <EuiTextArea
								fullWidth
								placeholder="Pas de commentaire du correcteur"
								value={practical_eval_module_instance.comment}
								disabled
							/> */}
						</EuiPanel>

						{practical_eval_module_instance.verificator ? (
							<>
								<EuiSpacer size="l" />

								<EuiPanel hasBorder>
									{practical_eval_module_instance.is_awaiting_verification && (
										<>
											<EuiFlexGroup>
												<EuiFlexItem>
													<EuiTitle size="xs">
														<h3> ⏳  En attente de validation par le vérificateur</h3>
													</EuiTitle>
												</EuiFlexItem>
											</EuiFlexGroup>
										</>
									)}

									{!practical_eval_module_instance.is_awaiting_verification && practical_eval_module_instance.validated_by_verificator && (
										<>
											<EuiFlexGroup>
												<EuiFlexItem>
													<EuiTitle size="xs">
														<h3> ✅  Validé par le vérificateur</h3>
													</EuiTitle>
												</EuiFlexItem>
											</EuiFlexGroup>
										</>
									)}

									{!practical_eval_module_instance.is_awaiting_verification && !practical_eval_module_instance.validated_by_verificator && (
										<>
											<EuiFlexGroup>
												<EuiFlexItem>
													<EuiTitle size="xs">
														<h3> ❌  Non validé par le vérificateur </h3>
													</EuiTitle>
												</EuiFlexItem>
											</EuiFlexGroup>
										</>
									)}
								</EuiPanel>
							</>
						) : (
							<></>
						)}
					</EuiAccordion>
				</EuiPanel>

				{(formation_instance.module_instances as unknown as TheoricEvaluationModuleInstance[])
					.filter((module_instance) => module_instance.is_rehabilitation)
					.map((module_instance) => {
						return (
							<>
								<EuiSpacer size="l" />

								<EuiFlexGroup>
									<EuiFlexItem>
										<EuiTitle size="m">
											<h2>
												Questionnaire intermédiaire{" "}
												{module_instance.validation_date ? (
													<>
														(validation le{" "}
														{new Date(module_instance.validation_date).toLocaleDateString("fr-FR", {
															timeZone: "Europe/Paris",
														}) +
															" à " +
															new Date(module_instance.validation_date).toLocaleTimeString("fr-FR", {
																timeZone: "Europe/Paris",
															})}
														)
													</>
												) : (
													<>(non validé)</>
												)}
											</h2>
										</EuiTitle>
									</EuiFlexItem>
									<EuiFlexItem grow={false}>
										<EuiTitle>
											<h2>{module_instance.is_validated ? "✅" : "❌"}</h2>
										</EuiTitle>
									</EuiFlexItem>
								</EuiFlexGroup>

								{/* {JSON.stringify(module_instance)} */}

								<EuiHorizontalRule />

								{module_instance.previous_attempts.map((attempt, i) => (
									<Attempt attempt={attempt} i={i} theoric_eval_module_instance={module_instance} />
								))}
							</>
						);
					})}

				{/* <pre>
                {JSON.stringify(formation_instance, null, 2)}
            </pre> */}
			</EuiPageTemplate.Section>
		</EuiPageTemplate>
	);
}

function Attempt({ attempt, i, theoric_eval_module_instance }: { attempt: any; i: string; theoric_eval_module_instance: TheoricEvaluationModuleInstance }) {
	const questions = attempt.answers;

	return (
		<>
			<EuiPanel hasBorder>
				<EuiAccordion
					id={i}
					buttonContent={
						"Essai n°" +
						(i + 1) +
						" - le " +
						new Date(attempt.submitted_at).toLocaleDateString("fr-FR", { timeZone: "Europe/Paris" }) +
						" à " +
						new Date(attempt.submitted_at).toLocaleTimeString("fr-FR", { timeZone: "Europe/Paris" })
					}
				>
					<EuiSpacer size="m" />

					<EuiFlexGrid columns={1}>
						{questions.map((question) => {
							const q = ((theoric_eval_module_instance.quizz_selected as unknown as Quizz).questions as unknown as QuizzQuestion[]).find((q) => q._id === question.question);

							return (
								<>
									<EuiFlexItem>
										<EuiPanel hasBorder>
											<RichText read_only value={q.question} onChange={(value) => {}} page_id={"special_comm"} />

											<EuiSpacer size="m" />

											<EuiFlexGrid columns={2}>
												{(q.possible_answers as unknown as QuizzPossibleAnswer[]).map((answer, i) => (
													<EuiFlexItem>
														<EuiPanel hasBorder>
															<EuiFlexGroup>
																<EuiFlexItem>
																	<EuiSwitch label={answer.text} checked={question.answer.includes(answer._id as unknown as string)} disabled onChange={() => {}} />
																</EuiFlexItem>
																<EuiFlexItem grow={false}>
																	{question.answer.includes(answer._id as unknown as string) &&
																		(
																			q.correct_answers as unknown as {
																				_id: string;
																			}[]
																		)
																			.map((a) => a._id)
																			.includes(answer._id as unknown as string) && <EuiText>✅</EuiText>}
																	{!question.answer.includes(answer._id as unknown as string) &&
																		(
																			q.correct_answers as unknown as {
																				_id: string;
																			}[]
																		)
																			.map((a) => a._id)
																			.includes(answer._id as unknown as string) && <EuiText>❌</EuiText>}
																	{question.answer.includes(answer._id as unknown as string) &&
																		!(
																			q.correct_answers as unknown as {
																				_id: string;
																			}[]
																		)
																			.map((a) => a._id)
																			.includes(answer._id as unknown as string) && <EuiText>❌</EuiText>}
																	{!question.answer.includes(answer._id as unknown as string) &&
																		!(
																			q.correct_answers as unknown as {
																				_id: string;
																			}[]
																		)
																			.map((a) => a._id)
																			.includes(answer._id as unknown as string) && <EuiText>✅</EuiText>}
																</EuiFlexItem>
															</EuiFlexGroup>
														</EuiPanel>
													</EuiFlexItem>
												))}
											</EuiFlexGrid>
										</EuiPanel>
									</EuiFlexItem>
								</>
							);
						})}
					</EuiFlexGrid>
				</EuiAccordion>
			</EuiPanel>

			<EuiSpacer size="s" />
		</>
	);
}
