import { EuiButton, EuiCallOut, EuiCard, EuiCheckableCard, EuiCheckbox, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiLoadingContent, EuiPanel, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui";
import { navigate, useMatch } from "@reach/router";
import { Types } from "mongoose";
import { useEffect, useState } from "react";
import RichText from "../../components/RichText";
import { useApi } from "../../effects/useApi";
import { FormationVersionInstance, Page, Quizz, QuizzPossibleAnswer, QuizzQuestion, TheoricEvaluationModule, TheoricEvaluationModuleInstance } from "../../types";

interface CurrentAttempt {
	started_at: Date | null,
	submitted_at: Date | null,
	score: number,
	is_validated: boolean,
	answers: Array<{
		question: string
		answer: string[]
	}>
}

function unique(value, index, self) {
	return self.indexOf(value) === index;
}


export default function TheoricEvaluationModulePage({ module_instance, formation_instance_id, refetch_formation, refetch_module_instance, formation_version_instance, navigate }: { module_instance: TheoricEvaluationModuleInstance, formation_instance_id?: string, refetch_formation?: () => void, refetch_module_instance: () => void, formation_version_instance: FormationVersionInstance, navigate?: (string) => void }) {

	const module = module_instance.module as unknown as TheoricEvaluationModule

	const match = useMatch("/formation_instances/:formation_instance_id/:module_instance_id/:page_id") as unknown as { page_id: string }

	// const [answers, setAnswers] = useState<{ question: string, answer: string }[]>([])

	const [current_attempt, setCurrentAttempt] = useState<CurrentAttempt>(module_instance.current_attempt || {
		started_at: null,
		submitted_at: null,
		score: 0,
		is_validated: false,
		answers: []
	})

	const [update_attempt] = useApi('POST', `formation_instances/${formation_instance_id}/module_instances/${module_instance._id}/quizz_attempt`, async (err, module_instance) => {
		if (err) return console.error(err)
		setCurrentAttempt(module_instance.current_attempt)

		await refetch_module_instance()
		await refetch_formation?.()
		// window.location.reload()
	})

	if (!module_instance) return <EuiLoadingContent lines={5} />

	const quizz_selected = module_instance.quizz_selected as unknown as Quizz

	const questions = quizz_selected.questions as unknown as QuizzQuestion[]

	const update_answer = (question: string, answer: string[]) => {

		const new_current_attempt = {
			...current_attempt
		}

		let new_answers = [...(new_current_attempt.answers || [])]

		const answer_index = new_answers.findIndex(a => a.question === question)

		if (answer_index === -1) {
			new_answers.push({ question, answer })
		} else {
			new_answers[answer_index].answer = answer
		}

		new_current_attempt.answers = new_answers

		update_attempt(new_current_attempt)
	}

	// Si submitted + valid

	if (module_instance.quizz_passed) {
		return <>

			<EuiCallOut color="success" iconType="check" size="m">
				<EuiFlexGroup gutterSize="none" style={{ placeItems: "center" }}>
					<EuiFlexItem><EuiText size="m" color="success">Ce module est complet, vous pouvez accéder au suivant.</EuiText></EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiButton color="success" onClick={() => navigate('/formation_instances/' + formation_version_instance._id + '/' + formation_version_instance.module_instances[2]._id as unknown as string)}>Module suivant</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiCallOut><EuiSpacer size="l" />

			<EuiEmptyPrompt
				iconType="check"
				title={<h2>Module validé</h2>}
				body={<p>Félicitations, vous avez réussi l'évaluation avec un score de {module_instance.quizz_score} / {(module_instance.quizz_selected as unknown as Quizz).questions.length}</p>}
			/>
		</>

	}

	if (module_instance.quizz_failed) {
		return <EuiEmptyPrompt
			iconType="cross"
			title={<h2>Module non validé</h2>}
			body={<p>Vous avez échoué l'évaluation avec un score de {module_instance.quizz_score} / {(module_instance.quizz_selected as unknown as Quizz).questions.length}</p>}
			actions={[<EuiButton onClick={() => update_attempt(current_attempt)}>Recommencer</EuiButton>]}
		/>
	}

	return <>
{/* 
		<EuiPanel hasBorder>
			Vous devez répondre à toutes les questions. Il n’y a pas de temps limite. Le score minimal est de {(module_instance.module as unknown as TheoricEvaluationModule).minimal_ratio}%.
		</EuiPanel>

		<EuiSpacer size="l" /> */}

		{questions.map((question, i) => <><EuiPanel hasBorder>
			<EuiTitle size="s">
				<h2>Question n° {i + 1}</h2>
			</EuiTitle>

			<EuiSpacer size="m" />

			<RichText
				value={question.question as unknown as any[]}
				onChange={(value) => { }}
				page_id={'question_' + question._id}
				read_only
			/>

			<EuiHorizontalRule margin="m" />

			{/* <pre>{JSON.stringify(current_attempt, null, 2)}</pre> */}


			{question.correct_answers.length > 1 ? <>

				<EuiCallOut color="primary" iconType="help" size="s">
					<EuiText size="s">Cette question comporte plusieurs réponses correctes. Vous devez toutes les sélectionner.</EuiText>
				</EuiCallOut>

				<EuiSpacer size="m" />

				{(question.possible_answers as unknown as QuizzPossibleAnswer[]).map(possible_answer => <>

					<EuiPanel
						hasBorder
						id={possible_answer._id as unknown as string}
						title={possible_answer.text}
						style={{ placeItems: "middle" }}
						onClick={(e) => {
							e.preventDefault()

							const _current_question_answer = current_attempt.answers.filter(a => a.question === question._id)

							if (_current_question_answer.length) {

								const current_question_answer = _current_question_answer[0]

								let new_answer = [...current_question_answer.answer]

								if (new_answer.includes(possible_answer._id as unknown as string)) {
									new_answer = new_answer.filter(a => a !== possible_answer._id as unknown as string)
								} else {
									new_answer.push(possible_answer._id as unknown as string)
								}

								update_answer(question._id as unknown as string, new_answer)


							} else {
								update_answer(question._id as unknown as string, [possible_answer._id as unknown as string])
							}
						}}
					>

						<EuiFlexGroup>
							<EuiFlexItem grow={false} color="subdued" style={{ placeContent: "center" }}>
								{current_attempt.answers.find(a => a.question === question._id)?.answer.includes(possible_answer._id as unknown as string) ? <EuiCheckbox
									id={possible_answer._id as unknown as string}
									label=""
									checked={true}
									onChange={(e) => { }}

								/> : <EuiCheckbox
									id={possible_answer._id as unknown as string}
									label=""
									checked={false}
									onChange={(e) => { }}
								/>}
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiText size="s">{possible_answer.text}</EuiText>
							</EuiFlexItem>

						</EuiFlexGroup>
					</EuiPanel>
					<EuiSpacer size="m" />
				</>)}

			</> : <>
				{(question.possible_answers as unknown as QuizzPossibleAnswer[]).map(possible_answer => <>
					<EuiCheckableCard
						id={possible_answer._id as unknown as string}
						checkableType="radio"
						label={possible_answer.text}
						name={question._id as unknown as string}
						value={possible_answer._id as unknown as string}
						checked={current_attempt?.answers?.find(a => a.question === question._id)?.answer.includes(possible_answer._id as unknown as string)}
						onChange={() => { update_answer(question._id as unknown as string, [possible_answer._id as unknown as string]) }}
					/>
					<EuiSpacer size="m" />
				</>)}
			</>}



		</EuiPanel><EuiSpacer size="xl" /></>)}

		<EuiPanel paddingSize="s" hasBorder>
			<EuiFlexGroup gutterSize="s">

				<EuiFlexItem style={{ textAlign: "center", placeContent: "center" }}></EuiFlexItem>

				<EuiFlexItem grow={false}>
					<EuiButton
						size="s"
						color="primary"
						iconSide="right"
						iconType="check"
						isDisabled={quizz_selected?.questions?.length !== current_attempt?.answers?.length}
						onClick={async () => { update_attempt({ ...current_attempt, submitted_at: new Date() }) }}
					>
						Valider
					</EuiButton>
				</EuiFlexItem>

			</EuiFlexGroup>

		</EuiPanel>


	</>
}

function DisplayPage({ path, page_id, formation_instance_id, module_instance_id, refetch_formation }: { path: string, page_id?: string, formation_instance_id?: string, module_instance_id?: string, refetch_formation?: () => void }) {

	const [page, setPage] = useState<Page | undefined>(undefined)

	const [fetch_page] = useApi('GET', `formation_instances/${formation_instance_id}/module_instances/${module_instance_id}/pages/${page_id}`, async (err, page) => {
		setPage(page)
		refetch_formation()
	})

	useEffect(() => {
		fetch_page()
	}, [page_id])

	if (!page) return <EuiLoadingContent lines={7} />

	return <div>
		{/* <EuiMarkdownFormat>{page.components}</EuiMarkdownFormat>; */}

		<RichText
			read_only
			value={page.components}
			onChange={(value) => { }}
			page_id={"course content"}
		/>
	</div>
}

function Empty({ path, page_id, navigate }: { path: string, page_id: string, navigate?: (path: string) => void }) {

	useEffect(() => {
		navigate(page_id)
	}, [page_id])

	return <>
	</>
}