import {
	EuiButton,
	EuiFlexGroup,
	EuiFlexItem,
	EuiPageHeader,
	EuiSelect,
	EuiPageTemplate,
	EuiSideNav,
	htmlIdGenerator,
	EuiDescribedFormGroup,
	EuiFormRow,
	EuiFieldText,
	EuiPanel,
	EuiSpacer,
	EuiButtonIcon,
	EuiTitle,
	EuiFieldSearch,
	EuiDatePicker,
	EuiSuperSelect,
	EuiToolTip,
	EuiTabs,
	EuiTab,
	EuiCallOut,
	EuiText,
	EuiImage,
	EuiLoadingContent,
	EuiPageBody,
	EuiPageSection,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiModalFooter,
	EuiButtonEmpty,
	EuiCheckbox,
	EuiCard,
} from "@elastic/eui";
import {
	Link,
	Redirect,
	redirectTo,
	Router,
	useLocation,
	useMatch,
} from "@reach/router";
import { Fragment, useEffect, useState } from "react";
import { API_PATH, useApi } from "../../../../effects/useApi";
import {
	CourseModule,
	Formation,
	FormationSession,
	FormationVersion,
	Module as FormationModule,
	Page,
	PracticalEvaluationModule,
	TheoricEvaluationModule,
} from "../../../../types";
import { Quizz } from "../../../../types/Quizz";
import MembersList from "./Members";
import ModuleEditor from "./Modules/Index";
import FormationVersions from "./Versions";
import RolesList from "./Roles";
import Communications from "./Communications";
import _ from "lodash";
import moment from "moment";
import QuizzesLibrary from "./QuizzesLibrary";
import RichText, { serialize } from "../../../../components/RichText";

interface RolesInterface {
	biologist_of: { _id: string }[];
	biologist_verificator_of: { _id: string }[];
	corrector_of: { _id: string }[];
	formation_corrector_of: { _id: string }[];
	formation_subreferent_of: { _id: string }[];
	formation_qualitician_of: { _id: string }[];
	member_of: { _id: string }[];
	operator_of: { _id: string }[];
	qualitician_of: { _id: string }[];
	subreferent_of: { _id: string }[];
	referent_of: { _id: string }[];
}

const update = _.throttle((cb, st) => {
	cb(st);
}, 1000);

export default function FormationAdmin({
	path,
	laboratory_id,
	formation_id,
	navigate,
}: {
	path: string;
	laboratory_id?: string;
	formation_id?: string;
	navigate?: any;
}) {
	const [formation, setFormation] = useState<Formation | null>(null);
	// const [formation_versions, setFormationVersions] = useState<FormationVersion[]>([])

	const [me, setMe] = useState<any>(null);

	const [fetch_me] = useApi("GET", "users/me", (err, user) => {
		if (!err) {
			setMe(user);
		}
	});

	const [roles, setRoles] = useState<RolesInterface | undefined>(undefined);

	const [fetch] = useApi("GET", "users/roles", async (err, roles) => {
		setRoles(roles);
	});

	const [fetch_formation] = useApi(
		"GET",
		`formations/${formation_id}`,
		(err, formation) => {
			if (err) {
				console.error(err);
				return;
			}
			setFormation(formation);

			setDirty(formation.dev_version?.changes?.length > 0);
			// setFormationVersions(formation_versions)
		}
	);

	const [publish_formation, publishing] = useApi(
		"POST",
		`formations/${formation_id}/publish`,
		async (err, formation) => {
			if (err) {
				console.error(err);
				return;
			}
			await fetch_formation();
			setDirty(false);

			navigate(`/formations/${formation_id}/versions`);

		}
	);

	const [show_publication_modal, set_show_publication_modal] =
		useState(false);

	const [changelog_header, set_changelog_header] = useState("");
	const [changelog, set_changelog] = useState("");

	const [should_notify, set_should_notify] = useState(false);

	useEffect(() => {
		fetch();
		fetch_formation();
		fetch_me();
	}, [formation_id]);

	const location = useLocation();

	const [dirty, setDirty] = useState<boolean>(false);

	if (!formation)
		return (
			<EuiPageTemplate>
				<EuiPageTemplate.Header
					description={<EuiLoadingContent lines={1} />}
				></EuiPageTemplate.Header>
				<EuiPageTemplate.Section>
					<EuiLoadingContent lines={5} />
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		);

	const dev_version = formation.dev_version as unknown as FormationVersion;

	if (!dev_version)
		return (
			<EuiPageTemplate>
				<EuiPageTemplate.Header
					description={<EuiLoadingContent lines={1} />}
					css={{ backgroundColor: "red !important" }}
				></EuiPageTemplate.Header>
				<EuiPageTemplate.Section>
					<EuiLoadingContent lines={5} />
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		);

	const [is_valid, errors] = (() => {
		let errors = [];

		const course_module = dev_version.modules[0] as unknown as CourseModule;
		const theoric_evaluation_module = dev_version
			.modules[1] as unknown as TheoricEvaluationModule;
		const practical_evaluation_module = dev_version
			.modules[2] as unknown as PracticalEvaluationModule;

		if (formation.is_live) {
		} else {
			if (course_module.pages.length === 0) {
				errors.push("Le cours doit comporter au moins une page.");
			}
		}

		if (
			(theoric_evaluation_module.quizzes as unknown as Quizz[]).filter(
				(quizz) => quizz.is_valid !== false
			).length === 0
		) {
			errors.push(
				"Le module doit comporter au moins un questionnaire avec au moins deux questions."
			);
		}

		// if (theoric_evaluation_module.quizzes.length && !(theoric_evaluation_module.quizzes as unknown as Quizz[]).some(q => q.questions.length >= 2)) {
		// 	errors.push("L'évaluation théorique doit comporter au moins un questionnaire avec au moins deux questions.")
		// }

		if (practical_evaluation_module.achievements.length === 0) {
			errors.push(
				"L'évaluation pratique doit comporter au moins un critère de compétence à valider."
			);
		}

		if (formation.correctors.length === 0) {
			errors.push(
				"Il doit y avoir au moins un évaluateur pour cette formation."
			);
		}

		if (!formation.rehab_delay) {
			errors.push(
				"La durée de questionnaire intermédiaire théorique doit être définie."
			);
		}

		if (errors.length) return [false, errors];

		return [true, []];
	})();

	const can_admin = roles
		? (roles.formation_qualitician_of || [])
				.map((r) => r._id)
				.includes(formation._id) ||
		  (roles.referent_of || [])
				.map((r) => r._id)
				.includes(formation.laboratory as unknown as string)
		: false;

	let tabs = can_admin
		? [
				{
					label: (
						<>
							<EuiToolTip
								content="Ajoutez la présentation, le questionnaire théorique et pratique qui seront soumis à l’opérateur"
								position="bottom"
							>
								<>Modules</>
							</EuiToolTip>
						</>
					),
					onClick: () => navigate("modules"),
					isSelected: location.pathname.indexOf("/modules") > 0,
				},
				{
					label: (
						<>
							<EuiToolTip
								content="Créez vos questionnaires"
								position="bottom"
							>
								<>Questionnaires</>
							</EuiToolTip>
						</>
					),
					onClick: () => navigate("quizzes"),
					isSelected: location.pathname.indexOf("/quizzes") > 0,
				},
				{
					label: (
						<>
							<EuiToolTip
								content="Demandez à l’opérateur de vous transmettre une preuve d’identité (carte nationale d’identité, carte CPS …) ou un une preuve de mesure réalisée, ou autre"
								position="bottom"
							>
								<>Document requis</>
							</EuiToolTip>
						</>
					),
					onClick: () => navigate("proofs"),
					isSelected: location.pathname.indexOf("/proofs") > 0,
				},
				{
					label: (
						<>
							<EuiToolTip
								content="Désignez le vérificateur, l’évaluateur et l’auditeur pour cette évaluation"
								position="bottom"
							>
								<>Rôles</>
							</EuiToolTip>
						</>
					),
					onClick: () => navigate("roles"),
					isSelected: location.pathname.indexOf("/roles") > 0,
				},
				{
					label: (
						<>
							<EuiToolTip
								content="Inscrivez les opérateurs à l'évaluation"
								position="bottom"
							>
								<>Opérateurs</>
							</EuiToolTip>
						</>
					),
					onClick: () => navigate("members"),
					isSelected: location.pathname.indexOf("/members") > 0,
				},
				{
					label: (
						<>
							<EuiToolTip
								content="Définissez la validité de cette évaluation"
								position="bottom"
							>
								<>Questionnaire intermédiaire</>
							</EuiToolTip>
						</>
					),
					onClick: () => navigate("settings"),
					isSelected: location.pathname.indexOf("/settings") > 0,
				},
				{
					label: (
						<>
							<EuiToolTip
								content="Définissez le recyclage de cette évaluation"
								position="bottom"
							>
								<>Recyclage</>
							</EuiToolTip>
						</>
					),
					onClick: () => navigate("recycling"),
					isSelected: location.pathname.indexOf("/recycling") > 0,
				},

				{
					label: (
						<>
							<EuiToolTip
								content="Envoyez un message à tous les opérateurs inscrits à cette évaluation"
								position="bottom"
							>
								<>Communications</>
							</EuiToolTip>
						</>
					),
					onClick: () => navigate("communications"),
					isSelected:
						location.pathname.indexOf("/communications") > 0,
				},
				{
					label: <>Historique des versions</>,
					onClick: () => navigate("versions"),
					isSelected: location.pathname.indexOf("/versions") > 0,
				},
		  ]
		: [];

	if (formation.is_live) {
		tabs.push({
			label: (
				<>
					<EuiToolTip
						content="Planifiez une session pour aider l’évaluateur à s’organiser"
						position="bottom"
					>
						<>Sessions</>
					</EuiToolTip>
				</>
			),
			onClick: () => navigate("sessions"),
			isSelected: location.pathname.indexOf("/sessions") > 0,
		});
	}

	const publish = async () => {
		const payload = {
			changelog: changelog,
			changelog_header:
				changelog_header ||
				`Version ${
					1 + formation?.published_versions || 1
				} — ${new Date().toLocaleDateString("fr-FR", {
					timeZone: "Europe/Paris",
				})} — ${me?.firstname} ${me?.lastname}`,
			should_notify,
			serialized_changelog: serialize(JSON.parse(changelog)),
		};

		publish_formation(payload);

		set_show_publication_modal(false);
	};

	return (
		<EuiPageTemplate>
			{show_publication_modal && (
				<EuiModal
					onClose={() => set_show_publication_modal(false)}
					style={{ width: 700 }}
				>
					<EuiModalHeader>
						<EuiModalHeaderTitle>
							<h1>Publier les changements</h1>
						</EuiModalHeaderTitle>
					</EuiModalHeader>

					<EuiModalBody>
						<EuiFormRow
							fullWidth
							label="Version de la formation"
							helpText="La version de la formation est incrémentée à chaque publication."
						>
							<EuiFieldText
								defaultValue={`Version ${
									1 + formation?.published_versions || 1
								} — ${new Date().toLocaleDateString("fr-FR", {
									timeZone: "Europe/Paris",
								})} — ${me?.firstname} ${me?.lastname}`}
								fullWidth
								onChange={(e) =>
									set_changelog_header(e.target.value)
								}
							/>
						</EuiFormRow>

						{changelog_header}

						<EuiFormRow
							label="Description des changements"
							fullWidth
						>
							<RichText
								value={changelog as unknown as any[]}
								onChange={(value) => {
									set_changelog(value);
								}}
								page_id={"changelog"}
							/>
						</EuiFormRow>
					</EuiModalBody>

					<EuiModalFooter>
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiCheckbox
									id={"notify_operators"}
									label="Avertir les opérateurs"
									checked={should_notify}
									onChange={(e) =>
										set_should_notify(e.target.checked)
									}
								/>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiButton onClick={() => publish()}>
									Publier
								</EuiButton>
							</EuiFlexItem>
						</EuiFlexGroup>
						{/* <EuiButton fill onClick={() => publish(true)}>Publier et avertir les opérateurs</EuiButton> */}
					</EuiModalFooter>
				</EuiModal>
			)}

			<EuiPageTemplate.Header
				pageTitle={
					<>
						{formation.image && (
							<>
								<EuiImage
									src={API_PATH + "videos/" + formation.image}
									size="s"
									hasShadow={false}
									style={{ borderRadius: 5 }}
									allowFullScreen
									alt=" "
								/>
								   
							</>
						)}
						{formation?.header}
					</>
				}
				rightSideItems={[
					// <EuiText>Version en cours: {(formation.dev_version as unknown as FormationVersion).version}</EuiText>,
					<EuiButton
						fill
						isLoading={publishing}
						isDisabled={!dirty || errors.length > 0}
						onClick={() => {
							// publish_formation()
							set_show_publication_modal(true);
						}}
					>
						Publier les changements
					</EuiButton>,
				]}
			>
				<EuiTabs
					size="s"
					bottomBorder={false}
					style={{ marginBottom: -25 }}
				>
					{tabs.map((tab) => (
						<EuiTab
							onClick={tab.onClick}
							isSelected={tab.isSelected}
						>
							{tab.label}
						</EuiTab>
					))}
				</EuiTabs>
			</EuiPageTemplate.Header>

			<EuiPageTemplate.Section>
				{errors.length > 0 && (
					<>
						<EuiCallOut
							title="Étapes à valider avant publication"
							color="warning"
							iconType="alert"
						>
							{errors.map((error) => (
								<EuiText>{error}</EuiText>
							))}
						</EuiCallOut>
						<EuiSpacer size="l" />
					</>
				)}

				<Router primary={false}>
					<QuizzesLibrary
						path="quizzes/*"
						formation={formation}
						modules={
							dev_version.modules as unknown as FormationModule[]
						}
						reload_formation={fetch_formation}
						setDirty={setDirty}
					/>
					<RolesList
						path="roles"
						laboratory_id={
							formation.laboratory as unknown as string
						}
						refetch_formation={fetch_formation}
						is_live={formation.is_live}
					/>
					<MembersList
						path="members"
						laboratory_id={
							formation.laboratory as unknown as string
						}
						formation={formation}
					/>
					<FormationVersions path="versions/*" />
					<ModulesList
						path="modules/*"
						modules={
							dev_version.modules as unknown as FormationModule[]
						}
						reload_formation={fetch_formation}
						setDirty={setDirty}
					/>
					<Communications
						path="communications"
						formation_id={formation_id}
						formation={formation}
						reload_formation={fetch_formation}
					/>
					<Settings
						path="settings"
						formation_id={formation_id}
						reload_formation={fetch_formation}
						setDirty={setDirty}
					/>
					<Recycling
						path="recycling"
						formation_id={formation_id}
						reload_formation={fetch_formation}
						setDirty={setDirty}
					/>
					<Proofs
						path="proofs"
						formation_id={formation_id}
						formation={formation}
						reload_formation={fetch_formation}
						setDirty={setDirty}
					/>
					<Sessions
						path="sessions"
						formation_id={formation_id}
						formation={formation}
						refetch_formation={fetch_formation}
					/>

					<Redirect from="/" to="modules" />
				</Router>
			</EuiPageTemplate.Section>
		</EuiPageTemplate>
	);
}

function ModulesList({
	path,
	modules,
	navigate,
	reload_formation,
	setDirty,
}: {
	path: string;
	modules: Array<FormationModule>;
	navigate?: any;
	reload_formation?: any;
	setDirty: (value: boolean) => void;
}) {
	const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false);

	const toggleOpenOnMobile = () => {
		setisSideNavOpenOnMobile(!isSideNavOpenOnMobile);
	};

	const match = useMatch("/formations/:formation_id/modules/:module_id/*");

	const match_page = useMatch(
		"/formations/:formation_id/modules/:module_id/pages/:_id"
	);
	const match_quizz = useMatch(
		"/formations/:formation_id/modules/:module_id/quizzes/:_id"
	);

	const get_children = (module: FormationModule): any => {
		if (module.type === "CourseModule") {
			return [
				...(
					(module as CourseModule).pages as unknown as Array<Page>
				).map((page: Page, _id) => ({
					id: page._id as string,
					name: "Page n°" + (_id + 1),
					onClick: () => {
						navigate(`${module._id}/pages/${page._id}`, {
							replace: true,
						});
						window.location.reload();
					},
					isSelected: match_page && match_page._id === page._id,
				})),
			];
		} else if (module.type === "TheoricEvaluationModule") {
			// return [...((module as TheoricEvaluationModule).quizzes as unknown as Array<Quizz>).map((quizz: Quizz, _id) => ({
			// 	id: quizz._id as string,
			// 	name: "Quizz n°" + (_id + 1),
			// 	onClick: () => navigate(`${module._id}/quizzes/${quizz._id}`),
			// 	isSelected: match_quizz && match_quizz._id === quizz._id,
			// })
			// )]
			return [];
		} else {
			return [];
		}
	};

	return (
		<>
			<EuiFlexGroup>
				<EuiFlexItem grow={false} style={{ width: 200 }}>
					<EuiSideNav
						heading={"Modules"}
						toggleOpenOnMobile={toggleOpenOnMobile}
						isOpenOnMobile={isSideNavOpenOnMobile}
						for
						items={[
							{
								id: "formation",
								name: "Évaluations",
								items:
									modules?.map((module) => ({
										id: module._id as string,
										name: module.header,
										items: get_children(module),
										onClick: () =>
											navigate(`${module._id}`),
										isSelected:
											module._id === match?.module_id,
										forceOpen: true,
									})) || [],
							},
						]}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<Router primary={false}>
						<ModuleEditor
							path=":module_id/*"
							reload_formation={reload_formation}
							setDirty={setDirty}
						/>
						<Redirect from="/" to={modules[0]._id as string} />
					</Router>

					{/* <EuiFlexGrid columns={1}>
					{modules.map(module => <EuiFlexItem>
						<EuiCard
							layout="horizontal"
							title={module.header}
							description={module.description}
							onClick={() => navigate(`modules/${module._id}`)}
						/>
					</EuiFlexItem>)}
				</EuiFlexGrid> */}
				</EuiFlexItem>
			</EuiFlexGroup>
		</>
	);
}

function Settings({
	path,
	formation_id,
	reload_formation,
	setDirty,
}: {
	path: string;
	formation_id: string;
	reload_formation: () => void;
	setDirty: (value: boolean) => void;
}) {
	const [formation, setFormation] = useState<Formation>(null);

	const [roles, setRoles] = useState<RolesInterface | undefined>(undefined);

	const [fetch] = useApi("GET", "users/roles", async (err, roles) => {
		setRoles(roles);
	});

	const [fetch_formation] = useApi(
		"get",
		`formations/${formation_id}`,
		(err: any, formation: Formation) => {
			setFormation(formation);
			setStrategy(formation.rehab_strategy as unknown as string);
		}
	);

	const [update_formation, updating] = useApi(
		"put",
		`formations/${formation_id}`,
		(err: any, formation: Formation) => {
			setFormation(formation);
			reload_formation();
		}
	);

	const [quizzes, setQuizzes] = useState<Quizz[]>([]);

	const [fetch_available_quizzes] = useApi(
		"get",
		`formations/${formation_id}/quizzes`,
		(err: any, quizzes: Array<Quizz>) => {
			setQuizzes(quizzes);
		}
	);

	const [strategy, setStrategy] = useState<string | null>(null);

	const [select_rehab_strategy] = useApi(
		"post",
		`formations/${formation_id}/select_rehab_strategy`,
		(err: any, formation: Formation) => {
			fetch_formation();
		}
	);

	useEffect(() => {
		fetch();
		fetch_formation();
		fetch_available_quizzes();
	}, []);

	if (!formation)
		return (
			<EuiPanel hasBorder>
				<EuiLoadingContent lines={5} />
			</EuiPanel>
		);

	let options = [
		{
			value: "0",
			text: "Aucune valeur sélectionnée",
		},
		{
			value: "-1",
			text: "Pas de questionnaire intermédiaire",
		},
		{
			value: "12",
			text: "Tous les 12 mois",
		},
		{
			value: "18",
			text: "Tous les 18 mois",
		},
		{
			value: "24",
			text: "Tous les 24 mois",
		},
		// {
		// 	value: "36",
		// 	text: "Tous les 36 mois",
		// },
	];

	let dunning_options = [
		{
			value: "0",
			text: "Pas de relance",
		},
		{
			value: "1",
			text: "Tous les jours",
		},
		{
			value: "2",
			text: "Tous les 2 jours",
		},
		{
			value: "3",
			text: "Tous les 3 jours",
		},
		{
			value: "7",
			text: "Toutes les semaines",
		},
	];

	if (
		window.location.hostname.indexOf("localhost") > -1 ||
		window.location.hostname.indexOf("netlify") > -1
	) {
		options = [
			{
				value: "99",
				text: "Toutes les heures (test)",
			},
			...options,
		];

		dunning_options = [
			{
				value: "99",
				text: "Toutes les 10 minutes (test)",
			},
			...dunning_options,
		];
	}

	if (!roles) return <EuiLoadingContent lines={5} />;

	if (
		!roles.referent_of
			.map((l) => l._id)
			.includes(formation.laboratory as unknown as string) &&
		!roles.formation_qualitician_of
			.map((l) => l._id)
			.includes(formation_id as unknown as string)
	) {
		return <Redirect to="/" />;
	}

	return (
		<EuiPanel hasBorder>
			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Questionnaire intermédiaire</h3>}
				description={
					<>
						A intervalle régulier, il est possible de demander à
						l'opérateur de ré-effectuer un questionnaire
						pour que son évaluation reste valide.
						<br />
						<br />
						Le délai s'applique à partir de la date de fin de l'évaluation.
					</>
				}
			>
				<EuiFormRow fullWidth label="Délai de réhabilitation">
					<EuiSelect
						isLoading={updating}
						fullWidth
						options={options}
						value={formation.rehab_delay?.toString() || "0"}
						onChange={(e) => {
							update_formation({
								rehab_delay: parseInt(e.target.value),
							});
							setDirty(true);
						}}
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>

			<EuiDescribedFormGroup
				title={<h3>Sélection du quizz</h3>}
				fullWidth
				description={
					<p>
						Choisissez le questionnaire que les opérateurs devront
						réaliser pour valider le module.
						<br />
						<Link to="../quizzes">
							Bibliothèque de questionnaires
						</Link>
						. Le pourcentage de bonnes réponses attendues pour
						le questionnaire intermédiaire est le
						même que celui défini pour le questionnaire.
					</p>
				}
			>
				{/* <EuiRange fullWidth step={10} showTicks value={minimal_score} onChange={(e: any) => { setMinimalScore(e.target.value); setNewRatio({ minimal_ratio: e.target.value }) }} /> */}

				<EuiSuperSelect
					options={[
						{
							value: "null",
							inputDisplay: <strong>Sélection aléatoire</strong>,
						},
						...quizzes
							.filter((quizz) => quizz.is_valid !== false)
							.map((quizz, i) => ({
								value: quizz._id as unknown as string,
								inputDisplay: `Questionnaire ${i + 1}`,
							})),
					]}
					fullWidth
					disabled={formation.rehab_delay === 0}
					valueOfSelected={strategy || "null"}
					onChange={(value) => {
						setStrategy(value === "null" ? null : value);
						select_rehab_strategy({
							strategy: value === "null" ? null : value,
						});
					}}
				/>
			</EuiDescribedFormGroup>

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Délai de relance</h3>}
				description={
					<Fragment>
						Délai après lequel les opérateurs de l'évaluation
						recevront une relance par email.
					</Fragment>
				}
			>
				<EuiFormRow
					fullWidth
					// label="Text field"
				>
					<EuiSelect
						isLoading={false}
						fullWidth
						options={dunning_options}
						value={formation.rehab_dunning_delay?.toString() || "0"}
						onChange={(e) =>
							update_formation({
								rehab_dunning_delay: parseInt(e.target.value),
							})
						}
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>
		</EuiPanel>
	);
}

function Recycling({
	path,
	formation_id,
	reload_formation,
	setDirty,
}: {
	path: string;
	formation_id: string;
	reload_formation: () => void;
	setDirty: (value: boolean) => void;
}) {
	const [formation, setFormation] = useState<Formation>(null);

	const [roles, setRoles] = useState<RolesInterface | undefined>(undefined);

	const [fetch] = useApi("GET", "users/roles", async (err, roles) => {
		setRoles(roles);
	});

	const [fetch_formation] = useApi(
		"get",
		`formations/${formation_id}`,
		(err: any, formation: Formation) => {
			setFormation(formation);
			// setStrategy(formation.recycling_delay as unknown as string);
		}
	);

	const [update_formation, updating] = useApi(
		"put",
		`formations/${formation_id}`,
		(err: any, formation: Formation) => {
			setFormation(formation);
			reload_formation();
		}
	);

	useEffect(() => {
		fetch();
		fetch_formation();
		// fetch_available_quizzes();
	}, []);

	if (!formation)
		return (
			<EuiPanel hasBorder>
				<EuiLoadingContent lines={5} />
			</EuiPanel>
		);

	let options = [
		{
			value: "-1",
			text: "Pas de recyclage",
		},
		{
			value: "36",
			text: "Tous les 36 mois",
		},
		{
			value: "48",
			text: "Tous les 48 mois",
		},
		{
			value: "60",
			text: "Tous les 60 mois",
		},
	];

	if (
		window.location.hostname.indexOf("localhost") > -1 ||
		window.location.hostname.indexOf("netlify") > -1
	) {
		options = [
			{
				value: "99",
				text: "Toutes les 2 heures (test)",
			},
			...options,
		];
	}

	if (!roles) return <EuiLoadingContent lines={5} />;

	if (
		!roles.referent_of
			.map((l) => l._id)
			.includes(formation.laboratory as unknown as string) &&
		!roles.formation_qualitician_of
			.map((l) => l._id)
			.includes(formation_id as unknown as string)
	) {
		return <Redirect to="/" />;
	}

	return (
		<EuiPanel hasBorder>
			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Recyclage</h3>}
				description={
					<>
						À intervalles réguliers, il est possible de demander à
						l'opérateur d’effectuer à nouveau l’ensemble des modules
						de l'évaluation.
						<br />
						<br />
						Le délai s'applique à partir de la date de fin de l'évaluation.
					</>
				}
			>
				<EuiFormRow fullWidth label="Délai de recyclage">
					<EuiSelect
						isLoading={updating}
						fullWidth
						options={options}
						value={formation.recycling_delay?.toString() || "-1"}
						onChange={(e) => {
							update_formation({
								recycling_delay: parseInt(e.target.value),
							});
							setDirty(true);
						}}
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>
		</EuiPanel>
	);
}

interface Proof {
	_id: string;
	text: string;
}

function Proofs({
	path,
	formation_id,
	formation,
	reload_formation,
	setDirty,
}: {
	path?: string;
	formation_id: string;
	formation: Formation;
	reload_formation: () => void;
	setDirty: (dirty: boolean) => void;
}) {
	const [proofs, setProofs] = useState<Proof[]>([]);

	const [update_proofs, updating] = useApi(
		"PUT",
		`formations/${formation_id}/proofs`,
		(err, instance) => {
			setDirty(true);
			reload_formation();
		}
	);

	useEffect(() => {
		if (formation.proofs) {
			setProofs(formation.proofs);
		} else {
			setProofs([]);
		}
	}, [formation_id]);

	return (
		<div>
			<EuiFlexGroup>
				<EuiFlexItem>
					<EuiTitle>
						<h3> </h3>
					</EuiTitle>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton
						iconType="plus"
						isLoading={updating}
						fill
						onClick={() => {
							const _id = Math.random().toString();
							setProofs([...proofs, { _id: _id, text: "" }]);
							update(update_proofs, {
								proofs: [...proofs, { _id: _id, text: "" }],
							});
						}}
					>
						Ajouter un document requis
					</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>

			<EuiSpacer size="l" />

			{proofs.map((proof) => {
				return (
					<>
						<EuiPanel key={proof._id}>
							<EuiFlexGroup>
								<EuiFlexItem>
									<EuiFieldText
										value={proof.text}
										placeholder="Description du document"
										fullWidth
										onChange={(e) => {
											const new_proofs = proofs.map(
												(a) => {
													if (a._id === proof._id) {
														return {
															...a,
															text: e.target
																.value,
														};
													} else {
														return a;
													}
												}
											);
											setProofs(new_proofs);

											update(update_proofs, {
												proofs: new_proofs,
											});
										}}
									/>
								</EuiFlexItem>
								<EuiFlexItem
									grow={false}
									style={{ placeContent: "center" }}
								>
									<EuiButtonIcon
										style={{ placeSelf: "center" }}
										iconType="trash"
										size="m"
										color="danger"
										onClick={() => {
											const new_proofs = proofs.filter(
												(a) => a._id !== proof._id
											);
											setProofs(new_proofs);

											update(update_proofs, {
												proofs: new_proofs,
											});
										}}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiPanel>
						<EuiSpacer size="m" />
					</>
				);
			})}
		</div>
	);
}

function Sessions({
	path,
	formation_id,
	formation,
	refetch_formation,
}: {
	path?: string;
	formation_id: string;
	formation: Formation;
	refetch_formation: () => void;
}) {
	const [formation_sessions, setFormationSessions] = useState<
		FormationSession[]
	>([]);

	const [new_date_session, setNewDateSession] = useState(moment());

	const [name, setName] = useState<string>("");

	const [fetch_sessions] = useApi(
		"GET",
		`formations/${formation_id}/sessions`,
		(err, sessions) => {
			if (err) {
				console.error(err);
			} else {
				setFormationSessions(sessions);
			}
		}
	);

	const [post_session] = useApi(
		"POST",
		`formations/${formation_id}/sessions`,
		(err, instance) => {
			if (err) {
				console.error(err);
			} else {
				fetch_sessions();
			}
		}
	);

	const [delete_session] = useApi(
		"DELETE",
		`formations/${formation_id}/sessions`,
		(err, instance) => {
			if (err) {
				console.error(err);
			} else {
				fetch_sessions();
			}
		}
	);

	useEffect(() => {
		fetch_sessions();
	}, [formation_id]);

	return (
		<div>
			<EuiPanel hasBorder>
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiFormRow label="Nom de la nouvelle session">
							<EuiFieldText
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</EuiFormRow>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiFormRow label="Date">
							<EuiDatePicker
								selected={new_date_session}
								onChange={(date) => setNewDateSession(date)}
								dateFormat="DD/MM/YYYY"
							/>
						</EuiFormRow>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiFormRow hasEmptyLabelSpace>
							<EuiButton
								iconType="plus"
								fill
								onClick={() => {
									post_session({
										date: new_date_session.toDate(),
										name,
									});
								}}
							>
								Ajouter une session
							</EuiButton>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiPanel>

			<EuiSpacer size="l" />

			{formation_sessions
				.sort((a: FormationSession, b: FormationSession) => {
					return (
						-new Date(a.date).getTime() + new Date(b.date).getTime()
					);
				})
				.map((session) => {
					return (
						<>
							<EuiCard
								hasBorder
								layout="horizontal"
								title={session.name || "Session sans nom"}
								key={session._id}
								isDisabled={
									new Date(session.date).getTime() +
										1000 * 60 * 60 * 24 <
									new Date().getTime()
								}
							>
								<EuiPageHeader
									// iconType="logoElastic"
									// pageTitle={"}
									description={
										"Le " +
										new Date(
											session.date
										).toLocaleDateString("fr-FR", {
											timeZone: "Europe/Paris",
										})
									}
									rightSideItems={[
										<EuiButton
											iconType="trash"
											color="danger"
											onClick={() => {
												delete_session({
													session_id: session._id,
												});
											}}
											isDisabled={
												new Date(
													session.date
												).getTime() +
													1000 * 60 * 60 * 24 <
												new Date().getTime()
											}
										>
											Supprimer
										</EuiButton>,
									]}
								></EuiPageHeader>
							</EuiCard>
							<EuiSpacer size="l" />
						</>
					);
				})}
		</div>
	);
}
