import {
	EuiButton,
	EuiCallOut,
	EuiButtonIcon,
	EuiEmptyPrompt,
	EuiFilePicker,
	EuiFlexGroup,
	EuiFlexItem,
	EuiNotificationBadge,
	EuiPageTemplate,
	EuiPanel,
	EuiProgress,
	EuiSpacer,
	EuiTitle,
	EuiText,
	EuiBadge,
	EuiImage,
	EuiLoadingContent,
	EuiHorizontalRule,
} from "@elastic/eui";
import { navigate, Redirect, Router, useMatch } from "@reach/router";
import axios from "axios";
import _ from "lodash";
import { Types } from "mongoose";
import { useEffect, useState } from "react";
import { API_PATH, useApi } from "../../effects/useApi";
import { Formation, FormationVersionInstance, Module, ModuleInstance, TheoricEvaluationModuleInstance, User } from "../../types";
import { SpecialCommunication } from "../../types/SpecialCommunication";
import Certificates from "./Certificates";
import Communications from "./Communications";
import ModuleInstancePage from "./Module";

const update = _.throttle((cb, st) => {
	cb(st);
}, 500);

function get_recycling_badge(formation_instance: FormationVersionInstance) {
	const should_recycle = formation_instance.should_recycle;

	// const expired =
	// 	new Date(formation_instance.valid_until).getTime() -
	// 		new Date().getTime() <
	// 	0;

	// if (expired) return <></>;

	// if (formation_instance.is_suspended) return <></>;

	if (should_recycle) return <EuiBadge color="warning">Date de recyclage : {new Date(formation_instance.recycle_on).toLocaleDateString("fr-FR", { timeZone: "Europe/Paris" })} </EuiBadge>;

	return <></>;
}

export default function FormationVersionInstancePage({ path, formation_instance_id, navigate }: { path: string; formation_instance_id?: string; navigate?: (path: string) => void }) {
	const [formation_version_instance, setFormationVersionInstance] = useState<FormationVersionInstance | undefined>(undefined);

	const [next_module_instance, setNextModuleInstance] = useState<ModuleInstance | undefined>(undefined);

	const [refetch_formation] = useApi("GET", `formation_instances/${formation_instance_id}`, async (err, formation_version_instance: FormationVersionInstance) => {
		setFormationVersionInstance(formation_version_instance);
	});

	const [confirm_course] = useApi("POST", `formation_instances/${formation_instance_id}/confirm_course`, async (err, formation_version_instance) => {
		refetch_formation();
	});

	const [fetch_next_module] = useApi("GET", `formation_instances/${formation_instance_id}/next_module`, async (err, module_instance) => {
		setNextModuleInstance(module_instance);
	});

	const [me, setMe] = useState<User | undefined>(undefined);

	const [fetch_me] = useApi("GET", `users/me`, async (err, me: User) => {
		setMe(me);
	});

	useEffect(() => {
		refetch_formation();
		fetch_next_module();
		fetch_me();
	}, []);

	const match = useMatch("/formation_instances/:formation_instance_id/:tab/*");

	const formation = formation_version_instance?.formation as unknown as Formation;

	if (me && formation && !((me.operator_of as unknown as string[]) || []).includes(formation_instance_id) && !me.formation_corrector_of.includes(formation._id)) {
		return <Redirect to={`/`} />;
	}

	if (!formation_version_instance)
		return (
			<EuiPageTemplate>
				<EuiPageTemplate.Header description={<EuiLoadingContent lines={1} />}></EuiPageTemplate.Header>
				<EuiPageTemplate.Section>
					<EuiLoadingContent lines={5} />
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		);

	if (!formation_version_instance.is_allowed_to_start)
		return (
			<EuiPageTemplate>
				<EuiPageTemplate.Header pageTitle={formation.header} tabs={[]} rightSideItems={[]} />

				<EuiPageTemplate.Section>
					<EuiPageTemplate.EmptyPrompt
						title={<h2>Confirmation de dispensation du présentation</h2>}
						// icon={<EuiImage size="fullWidth" src={illustration} alt="" />}
						color="plain"
						layout="horizontal"
						body={
							<>
								<EuiTitle size="m">
									<h2>Conditions Générales d'Utilisation (CGU) de la Plateforme 360Skillvue</h2>
								</EuiTitle>
								<EuiHorizontalRule />
								{/* <EuiText>
								Je confirme avoir bien dispensé la formation {formation.header} à l’opérateur {(formation_instance.operator as unknown as User).firstname}{" "}
								{(formation_instance.operator as unknown as User).lastname} et qu’il ou elle a donc bien reçu toutes les informations lui permettant d’être évalué(e).
							</EuiText> */}

								<EuiText>
									<strong>1. Objet</strong>
									Les présentes Conditions Générales d'Utilisation (CGU) régissent l'accès et l'utilisation de la plateforme 360Skillvue, qui permet aux salariés d'entreprises
									clientes de bénéficier d'analyses ergonomiques à distance.
								</EuiText>
								<br />
								<EuiText>
									<strong>2. Acceptation des CGU</strong>
									En cochant la case prévue à cet effet, le salarié accepte sans réserve les présentes CGU. Si le salarié n'accepte pas les CGU, il ne pourra pas accéder à la
									plateforme.
								</EuiText>
								<br />
								<EuiText>
									<strong>3. Accès à la Plateforme</strong>
									L'accès à la plateforme est réservé aux salariés dont l'entreprise a souscrit un contrat avec 360Skillvue. Chaque salarié se voit attribuer des identifiants
									personnels, qu'il s'engage à ne pas partager.
								</EuiText>
								<br />
								<EuiText>
									<strong>4. Utilisation de la Plateforme</strong>
									Le salarié s'engage à utiliser la plateforme conformément aux instructions données par 360Skillvue. Il s'interdit toute utilisation abusive ou frauduleuse de la
									plateforme.
								</EuiText>
								<br />
								<EuiText>
									<strong>5. Protection des Données Personnelles</strong>
									Conformément au RGPD, 360Skillvue s'engage à protéger les données personnelles des utilisateurs. Les données collectées sur la plateforme sont hébergées sur des
									serveurs sécurisés en France et sont utilisées exclusivement dans le cadre de l'analyse ergonomique.
								</EuiText>
								<br />
								<EuiText>
									<strong>6. Droits et Obligations de l'Utilisateur</strong>
									Le salarié doit fournir des informations exactes lors de l'utilisation de la plateforme. Il est également responsable de la confidentialité de ses identifiants et
									de toute activité réalisée sous son compte.
								</EuiText>
								<br />
								<EuiText>
									<strong>7. Propriété Intellectuelle</strong>
									Tous les contenus, textes, graphiques, logos, et interfaces présents sur la plateforme sont la propriété exclusive de 360Skillvue. Toute reproduction, distribution,
									ou utilisation non autorisée est strictement interdite.
								</EuiText>
								<br />
								<EuiText>
									<strong>8. Limitation de Responsabilité</strong>
									360Skillvue ne saurait être tenue responsable des interruptions temporaires de service ou des problèmes techniques indépendants de sa volonté.
								</EuiText>
								<br />
								<EuiText>
									<strong>9. Modification des CGU</strong>
									360Skillvue se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs seront informés de toute modification par un avis publié sur la
									plateforme.
								</EuiText>
								<br />
								<EuiText>
									<strong>10. Résiliation</strong>
									En cas de non-respect des CGU par le salarié, 360Skillvue se réserve le droit de suspendre ou de résilier son accès à la plateforme.
								</EuiText>
								<br />
								<EuiText>
									<strong>11. Droit Applicable et Juridiction</strong>
									Les présentes CGU sont régies par le droit français. Tout litige relatif à l'exécution ou à l'interprétation des CGU sera soumis aux tribunaux compétents de Paris.
								</EuiText>
								<br />
								{/* <p>
									Je confirme avoir bien dispensé la formation {formation.header} à l’opérateur {(formation_version_instance.operator as unknown as User).firstname}{" "}
									{(formation_version_instance.operator as unknown as User).lastname} et qu’il a donc bien reçu toutes les informations lui permettant d’être évalué(e).
								</p> */}
							</>
						}
						actions={
							<EuiButton color="primary" fill onClick={() => confirm_course()}>
								Valider
							</EuiButton>
						}
					/>
				</EuiPageTemplate.Section>
			</EuiPageTemplate>
		);

	let all_proofs_filled = true;

	if (!formation.proofs || !formation_version_instance.proofs) all_proofs_filled = true;
	else if (formation_version_instance.proofs.filter((proof) => !proof.media_id).length) all_proofs_filled = false;

	const expired = new Date(formation_version_instance.valid_until).getTime() - new Date().getTime() < 0;

	return (
		<EuiPageTemplate>
			<EuiPageTemplate.Header
				pageTitle={
					<>
						{formation.image && (
							<>
								<EuiImage src={API_PATH + "videos/" + formation.image} size="s" hasShadow={false} style={{ borderRadius: 5 }} allowFullScreen alt=" " />
								   
							</>
						)}
						{formation?.header}
					</>
				}
				// description={(formation_version_instance.score === formation_version_instance.max_score && (formation_version_instance.formation as unknown as Formation).rehab_delay > -1) ? `Formation validée le ${new Date(formation_version_instance.validated_at).toLocaleDateString()}` : undefined}
				tabs={[
					{
						label: "Mes documents",
						onClick: () => navigate("proofs"),
						isSelected: match?.tab === "proofs",
					},

					...(formation_version_instance.module_instances as unknown as ModuleInstance[])
						.filter((instance) => !instance.is_rehabilitation)
						.map((module_instance, index) => ({
							label: (module_instance.module as unknown as Module).header,
							disabled: ((!module_instance.is_current && !module_instance.is_validated && !formation.is_live) || !all_proofs_filled) && match?.tab !== module_instance._id,

							onClick: () => navigate(`${module_instance._id}`),
							isSelected: match?.tab === module_instance._id,
						})),

					{
						label: "Questionnaire intermédiaire",
						// disabled: (!module_instance.is_current && !module_instance.is_validated && !formation.is_live) || !all_proofs_filled,

						onClick: () => navigate(`rehabilitations`),
						isSelected: match?.tab === "rehabilitations",

						append:
							!expired &&
							(formation_version_instance.module_instances as unknown as TheoricEvaluationModuleInstance[]).filter((instance) => instance.is_rehabilitation && !instance.quizz_passed)
								.length > 0 ? (
								<EuiNotificationBadge className="eui-alignCenter" size="m">
									{
										(formation_version_instance.module_instances as unknown as TheoricEvaluationModuleInstance[]).filter(
											(instance) => instance.is_rehabilitation && !instance.quizz_passed
										).length
									}
								</EuiNotificationBadge>
							) : (
								<></>
							),
					},

					{
						label: "Communications",
						onClick: () => navigate("communications"),
						isSelected: match?.tab === "communications",
						append:
							(formation_version_instance.special_communications as unknown as Array<SpecialCommunication>).filter((comm) => comm.is_signed === false).length > 0 ? (
								<EuiNotificationBadge className="eui-alignCenter" size="m">
									{(formation_version_instance.special_communications as unknown as Array<SpecialCommunication>).filter((comm) => comm.is_signed === false).length}
								</EuiNotificationBadge>
							) : (
								<></>
							),
					},

					{
						label: "Mes attestations",
						onClick: () => navigate("certificates"),
						isSelected: match?.tab === "certificates",
					},
				]}
				rightSideItems={[
					<EuiButton color="text" disabled fill size="s">
						{formation_version_instance.score === formation_version_instance.max_score ? (
							<EuiProgress value={100} max={100} size="m" color={expired ? "danger" : "success"} valueText={true} />
						) : (
							<EuiProgress
								value={Math.floor((100 * formation_version_instance.score) / formation_version_instance.max_score)}
								max={100}
								size="m"
								color={expired ? "danger" : "primary"}
								valueText={true}
							/>
						)}
					</EuiButton>,
				]}
			>
				{formation_version_instance.is_started && formation_version_instance.has_been_finished === true && (
					<>
						{expired ? (
							<>
								{" "}
								<EuiBadge color="danger">Évaluation expirée</EuiBadge>
							</>
						) : (
							<>
								{formation_version_instance.is_suspended ? (
									<>
										<EuiBadge color="warning">Évaluation suspendue</EuiBadge>
									</>
								) : (
									<>
										{formation_version_instance.is_validated && <EuiBadge color="success">Évaluation validée</EuiBadge>}

										{formation_version_instance.valid_until && (
											<EuiBadge color="warning">
												Évaluation valable jusqu'au{" "}
												{new Date(formation_version_instance.valid_until).toLocaleDateString("fr-FR", {
													timeZone: "Europe/Paris",
												})}
											</EuiBadge>
										)}
									</>
								)}
							</>
						)}
					</>
				)}

				{get_recycling_badge(formation_version_instance)}
			</EuiPageTemplate.Header>

			<EuiPageTemplate.Section>
				<Router primary={false}>
					<Communications path="communications/*" formation_instance_id={formation_instance_id} refetch_formation={refetch_formation} />
					<Certificates path="certificates/*" formation_instance_id={formation_instance_id} refetch_formation={refetch_formation} />
					<Proofs
						path="proofs/*"
						formation_version_instance={formation_version_instance}
						formation_instance_id={formation_instance_id as unknown as Types.ObjectId}
						refetch_formation={refetch_formation}
					/>
					<Rehabilitations
						path="rehabilitations/*"
						module_instances={(formation_version_instance.module_instances as unknown as TheoricEvaluationModuleInstance[]).filter((instance) => instance.is_rehabilitation)}
						formation_version_instance={formation_version_instance}
						formation_instance_id={formation_instance_id as unknown as Types.ObjectId}
						refetch_formation={refetch_formation}
					/>
					<ModuleInstancePage path=":module_instance_id/*" formation_version_instance={formation_version_instance} refetch_formation={refetch_formation} />
					<Default path="*" formation_version_instance={formation_version_instance} />
				</Router>

				{/* <Router>
				<RolesList path="roles" laboratory_id={formation.laboratory as unknown as string} />
				<MembersList path="members" laboratory_id={formation.laboratory as unknown as string} formation={formation} />
				<FormationVersions path="versions/*" />
				<ModulesList path="modules/*" modules={dev_version.modules as unknown as FormationModule[]} reload_formation={fetch_formation} setDirty={setDirty} />
				<Communications path="communications" formation_id={formation_id} />
				<Redirect from="/" to="modules" />
			</Router> */}
			</EuiPageTemplate.Section>
		</EuiPageTemplate>
	);
}

function Rehabilitations({
	path,
	module_instances,
	formation_instance_id,
	refetch_formation,
	formation_version_instance,
}: {
	path: string;
	module_instances: TheoricEvaluationModuleInstance[];
	formation_instance_id?: Types.ObjectId;
	formation_version_instance: FormationVersionInstance;
	refetch_formation: () => void;
}) {
	const first_rehab = module_instances.filter((instance) => !instance.quizz_passed)[0];

	if (new Date(formation_version_instance.valid_until).getTime() - new Date().getTime() < 0) {
		return (
			<EuiEmptyPrompt
				title={<h2>Votre évaluation a expiré</h2>}
				body={<p>Vos droits d'accès à l'automate vont être révoqués. Veuillez vous rapprocher de votre administrateur afin de repasser votre évaluation.</p>}
				// actions={<EuiButton href={`/formations/${formation_instance_id}/rehabilitations/${first_rehab._id}`} fill>Accéder au module de réhabilitation</EuiButton>}
			/>
		);
	}

	if (!first_rehab) {
		const last = module_instances[module_instances.length - 1];
		return (
			<>
				{last ? (
					<EuiCallOut iconType="clock">
						Prochaine réhabilitation le{" "}
						{new Date(formation_version_instance.valid_until).toLocaleDateString("fr-FR", {
							timeZone: "Europe/Paris",
						})}{" "}
						à{" "}
						{new Date(formation_version_instance.valid_until).toLocaleTimeString("fr-FR", {
							timeZone: "Europe/Paris",
						})}
					</EuiCallOut>
				) : (
					<EuiCallOut iconType="clock">Vous n'avez pas encore de questionnaire intermédiaire'.</EuiCallOut>
				)}

				<EuiSpacer size="m" />
				{module_instances.map((instance) => {
					return (
						<>
							<EuiPanel hasBorder>
								Réhabilitation validée le{" "}
								{new Date(instance.validation_date).toLocaleDateString("fr-FR", {
									timeZone: "Europe/Paris",
								})}{" "}
								à{" "}
								{new Date(instance.validation_date).toLocaleTimeString("fr-FR", {
									timeZone: "Europe/Paris",
								})}
							</EuiPanel>
							<EuiSpacer size="m" />
						</>
					);
				})}
			</>
		);
	}

	return (
		<>
			{/* {new Date(formation_version_instance.valid_until).getTime()}
		—
		{(new Date()).getTime()}

		{new Date(formation_version_instance.valid_until).getTime() < (new Date()).getTime() ? "expirée" : "valide"} */}
			{/* <pre>{JSON.stringify(module_instances, null, 2)}</pre> */}
			<ModuleInstancePage
				path=":module_instance_id/*"
				formation_version_instance={formation_version_instance}
				formation_instance_id={formation_instance_id as unknown as string}
				module_instance_id={first_rehab._id as unknown as string}
				refetch_formation={refetch_formation}
			/>
		</>
	);
}

interface Proof {
	_id: string;
	text: string;
	media_id?: Types.ObjectId;
}

function Proofs({
	path,
	formation_instance_id,
	refetch_formation,
	formation_version_instance,
}: {
	path: string;
	formation_instance_id?: Types.ObjectId;
	formation_version_instance: FormationVersionInstance;
	refetch_formation: () => void;
}) {
	const [proofs, setProofs] = useState<Proof[]>([]);

	const [update_module_instance_proof] = useApi("put", `formation_instances/${formation_instance_id}/proofs`, (err, instance) => {
		refetch_formation();
	});

	useEffect(() => {
		if ((formation_version_instance as unknown as FormationVersionInstance).proofs) {
			setProofs((formation_version_instance as unknown as FormationVersionInstance).proofs);
		} else {
			setProofs([]);
		}
	}, [formation_instance_id]);

	return (
		<>
			{(!proofs || proofs.length === 0 || proofs.filter((proof) => !proof.media_id).length === 0) && (
				<>
					<EuiCallOut color="success" iconType="check" size="m">
						<EuiFlexGroup gutterSize="none" style={{ placeItems: "center" }}>
							<EuiFlexItem>
								<EuiText size="m" color="success">
									Ce module est complet, vous pouvez accéder au suivant.
								</EuiText>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiButton color="success" onClick={() => navigate(formation_version_instance.module_instances[0]._id as unknown as string)}>
									Module suivant
								</EuiButton>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiCallOut>
					<EuiSpacer size="l" />
				</>
			)}

			{(!proofs || proofs.length === 0) && <EuiEmptyPrompt title={<h2>Aucun document à fournir</h2>} body={<p>Vous n'avez pas besoin de fournir de document pour cette formation</p>} />}

			{proofs.map((proof) => {
				return (
					<>
						<EuiPanel key={proof._id}>
							<EuiFlexGroup>
								<EuiFlexItem>
									<EuiTitle>
										<h3>{proof.text}</h3>
									</EuiTitle>
								</EuiFlexItem>
								{proof.media_id && (
									<EuiFlexItem grow={false} style={{ placeContent: "center" }}>
										<EuiButtonIcon
											style={{ placeSelf: "center" }}
											iconType="trash"
											size="m"
											color="danger"
											onClick={() => {
												// delete the media_id of the proof
												const new_proofs = proofs.map((a) => {
													if (a._id === proof._id) {
														return {
															...a,
															media_id: undefined,
														};
													} else {
														return a;
													}
												});

												setProofs(new_proofs);

												update(update_module_instance_proof, { proofs: new_proofs });
											}}
										/>
									</EuiFlexItem>
								)}
							</EuiFlexGroup>

							<EuiSpacer size="m" />

							{proof.media_id && <img src={API_PATH + "videos/" + proof.media_id} width="100%" style={{ maxWidth: "90vw" }} alt=" " />}

							{!proof.media_id && (
								<EuiFilePicker
									id={"file"}
									multiple
									initialPromptText={"Choisissez un fichier"}
									onChange={(files: FileList) => {
										const formData = new FormData();
										for (let i = 0; i < files.length; i++) {
											formData.append("file", files[i]);
										}
										const config = {
											onUploadProgress: (progressEvent) => {},
											headers: {
												"content-type": "multipart/form-data",
											},
										};
										axios
											.post(API_PATH + "upload", formData, config)
											.then(({ data }: any) => {
												const new_proofs = proofs.map((a) => {
													if (a._id === proof._id) {
														return {
															...a,
															media_id: data.media_id,
														};
													} else {
														return a;
													}
												});
												setProofs(new_proofs);
												update(update_module_instance_proof, { proofs: new_proofs });
											})
											.catch((error) => {
												console.error(error);
											});
									}}
									fullWidth
								/>
							)}
						</EuiPanel>
						<EuiSpacer size="m" />
					</>
				);
			})}
		</>
	);
}

function Default({ path, formation_version_instance }: { path: string; formation_version_instance?: FormationVersionInstance }) {
	// return <>{formation_version_instance.next_path}</>

	return (
		<div>
			Chargement...
			{formation_version_instance.next_path && <Redirect to={formation_version_instance.next_path} />}
		</div>
	);
}
