import { useContext, useEffect, useState } from "react";
// import { useAuth0 } from '@auth0/auth0-react';

import { UserContext } from "../context/UserContext";

let backend_url;

if (window.location.hostname.indexOf("localhost") > -1 || window.location.hostname.indexOf("netlify") > -1) {
	backend_url = "https://app-8ed40854-041f-4598-966a-8030c4be6ce1.cleverapps.io/"; // test
} else {
	backend_url = "https://app-2d4680df-1964-4efd-961c-99587a28d899.cleverapps.io/";
}

const baseURL = window.location.origin + "/";
// export const API_PATH = (baseURL === "http://localhost:3000/") ? "http://localhost:8081/" : backend_url

export const API_PATH = "https://app-2d4680df-1964-4efd-961c-99587a28d899.cleverapps.io/";

interface ApiCallState {
	error: any;
	loading: boolean;
	input: any;
	output: any;
	should_fetch: boolean;
	endpoint: string;
}

const useApiRaw = (method: string, endpoint: string, should_fetch: boolean = false, callback = (a: any, b: any) => {}) => {
	// const { getAccessTokenSilently } = useAuth0();
	const [state, setState] = useState<ApiCallState>({
		error: null,
		loading: should_fetch,
		input: null,
		output: null,
		should_fetch: should_fetch,
		endpoint,
	});

	const [userContext, setUserContext] = useContext(UserContext);

	const loggedAs = userContext?.loggedAs;

	const token = loggedAs ? loggedAs.token : userContext?.token;

	// console.log(loggedAs ? "USING LOGGEDAS TOKEN" : "USING USERCONTEXT TOKEN");

	useEffect(() => {
		(async () => {
			if (!state.should_fetch) {
				setState({ ...state, loading: false, should_fetch: false });
				return;
			}

			try {
				let response;

				if (method.toLowerCase() === "get") {
					const params = new URLSearchParams(state.input).toString();
					response = await fetch(API_PATH + endpoint + (params ? "?" + params : ""), {
						method: method,
						credentials: "include",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					});
				} else {
					response = await fetch(API_PATH + endpoint, {
						method: method,
						credentials: "include",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify(state.input),
					});
				}

				if (!response.ok) {
					const body = await response.json();
					console.error(body);
					throw body;
				} else {
					const data = await response.json();
					callback(null, data);
					setState({ ...state, output: data, error: null, loading: false, should_fetch: false });
				}
			} catch (error) {
				callback(error, null);
				setState({ ...state, error, loading: false });
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.input]);

	return [
		state.loading,
		state.error,
		state.output,
		async (input = {}, endpoint = null) => {
			if (endpoint) {
				await setState({ ...state, input, loading: true, should_fetch: true, endpoint });
			} else {
				await setState({ ...state, input, loading: true, should_fetch: true });
			}
			return state.output;
		},
	];
};

export const useApi = (method: string, endpoint: string, callback = (a: any, b: any) => {}) => {
	const [loading, error, result, fetch] = useApiRaw(method, endpoint, false, callback);
	return [fetch, loading, error, result];
};
