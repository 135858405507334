import { EuiBadge, EuiBasicTable, EuiButton, EuiComboBox, EuiDescribedFormGroup, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel, EuiSelect, EuiSpacer } from '@elastic/eui';
import moment from 'moment';
import { useEffect, useState } from "react";
import { API_PATH, useApi } from '../../../effects/useApi';
import { Formation, SpecialCommunicationTemplate, User } from '../../../types';


interface CommunicationStat {
	operator_id: string,
	operator_name: string,
	formation_name: string,
	template_id: string,
	template_header: string,
	sent_on: Date,
	is_read: boolean,
	is_critical: boolean,
	read_on: Date,
	link: string,
}


export default function Communications({ path, laboratory_id, me }: { path: string, laboratory_id?: string, me: User }) {

	const [stats, setStats] = useState<CommunicationStat[]>([])

	const [possible_operators, setPossibleOperators] = useState<{ label: string, value?: string }[]>([])
	const [possible_formations, setPossibleFormations] = useState<{ label: string, value?: string }[]>([])
	const [possible_templates, setPossibleTemplates] = useState<{ label: string, value?: string }[]>([])

	const [operators_filter, setOperatorsFilter] = useState<{ label: string, value?: string }[]>([])
	const [formations_filter, setFormationsFilter] = useState<{ label: string, value?: string }[]>([])
	const [templates_filter, setTemplatesFilter] = useState<{ label: string, value?: string }[]>([])

	const [status_filter, setStatusFilter] = useState<string>('all')
	const [type_filter, setTypeFilter] = useState<string>('all')

	const [page, setPage] = useState<number>(0)
	const [count, setCount] = useState<number>(0)

	const [fetch_ranges] = useApi('GET', `stats/communications/${laboratory_id}/ranges`, (err, { possible_formations, possible_operators, possible_templates }) => {
		if (err) { console.error(err); return }
		setPossibleFormations(possible_formations.filter(formation => {
			if (me.referent_of.length > 0) return true
			if (me.biologist_of.length > 0 && me.biologist_of.includes(formation._id)) return true
			return false
		}).map((formation: Formation) => ({ _id: formation._id, label: formation.header })))
		setPossibleOperators(possible_operators.map((operator: User) => ({ label: `${operator.firstname} ${operator.lastname}`, _id: operator._id })))
		setPossibleTemplates(possible_templates.map((template: SpecialCommunicationTemplate) => ({ _id: template._id, label: template.header })))
	})

	const [fetch_stats] = useApi('GET', `stats/communications/${laboratory_id}`, (err, { stats, page, count }) => {
		if (err) { console.error(err); return }
		setStats(stats.filter(stat => {
			if (me.referent_of.length > 0) return true
			if (me.biologist_of.length > 0 && me.biologist_of.includes(stat.formation_id)) return true

			return false
		}))

		setPage(page)
		setCount(count)
	})

	function build_body(body: any) {
		return {
			page,
			// @ts-ignore
			selected_operators: operators_filter.map(({ _id }) => _id),
			// @ts-ignore
			selected_formations: formations_filter.map(({ _id }) => _id),
			// @ts-ignore
			selected_templates: templates_filter.map(({ _id }) => _id),
			selected_status: status_filter,
			selected_type: type_filter,
			...body
		}
	}


	useEffect(() => {
		fetch_ranges()
		fetch_stats()
	}, [laboratory_id, path])


	return <>
		<EuiPanel hasBorder>

			<EuiFlexGroup>
				<EuiFlexItem></EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton
						iconType="download"
						color="text"
						target="_blank"
						href={`${API_PATH}stats/communications/${laboratory_id}?${new URLSearchParams(build_body({
							format: "csv"
						})).toString()}`}
					>
						Télécharger la vue filtrée au format CSV
					</EuiButton>

				</EuiFlexItem>
			</EuiFlexGroup>

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Opérateurs</h3>}
				description={<>Laissez vide pour sélectionner tous les opérateurs de l'espace.</>}
			>
				<EuiFormRow fullWidth>
					<EuiComboBox
						placeholder="Sélectionnez un ou plusieurs opérateur(s)"
						options={possible_operators}
						// @ts-ignore
						onChange={(values) => {
							setOperatorsFilter(values);
							fetch_stats(build_body({
								// @ts-ignore
								selected_operators: values.map(({ _id }) => _id)
							}))
						}}

						selectedOptions={operators_filter}
						isClearable={true}
						fullWidth
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup >

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Évaluations</h3>}
				description={<>Laissez vide pour sélectionner toutes les évaluations de l'espace.</>}
			>
				<EuiFormRow fullWidth>
					<EuiComboBox
						placeholder="Sélectionnez une ou plusieurs évaluations(s)"
						options={possible_formations}
						// @ts-ignore
						onChange={(values) => {
							setFormationsFilter(values);
							fetch_stats(build_body({
								// @ts-ignore
								selected_formations: values.map(v => v._id),
							}))
						}}
						selectedOptions={formations_filter}
						isClearable={true}
						fullWidth
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Communications</h3>}
				description={<>Laissez vide pour sélectionner toutes les communications de l'espace.</>}
			>
				<EuiFormRow fullWidth>
					<EuiComboBox
						placeholder="Sélectionnez une ou plusieurs communication(s)"
						options={possible_templates}
						// @ts-ignore
						onChange={(values) => {
							setTemplatesFilter(values); fetch_stats(build_body({
								// @ts-ignore
								selected_templates: values.map(v => v._id),
							}))
						}}
						selectedOptions={templates_filter}
						isClearable={true}
						fullWidth
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Statut</h3>}
				description={<></>}
			>
				<EuiFormRow fullWidth>
					<EuiSelect
						fullWidth
						options={[
							{ value: 'all', text: 'Toutes' },
							{ value: 'read', text: 'Lues' },
							{ value: 'not_read', text: 'Non lues' },
						]}
						value={status_filter}
						onChange={(e) => {
							setStatusFilter(e.target.value)
							fetch_stats(build_body({
								selected_status: e.target.value
							}))
						}}
					/>
				</EuiFormRow>

			</EuiDescribedFormGroup>

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Type de communication</h3>}
				description={<></>}
			>
				<EuiFormRow fullWidth>
					<EuiSelect
						fullWidth
						options={[
							{ value: 'all', text: 'Toutes' },
							{ value: 'critical', text: 'Critiques' },
							{ value: 'non_critical', text: 'Non critiques' },
						]}
						value={type_filter}
						onChange={(e) => {
							setTypeFilter(e.target.value)
							fetch_stats(build_body({
								selected_type: e.target.value
							}))
						}}
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>

		</EuiPanel>

		<EuiSpacer size="xxl" />

		<div>
			{/* add pagination */}
			{/* @ts-ignore */}
			<EuiBasicTable noItemsMessage="Aucun résultat trouvé" items={stats} columns={[{
				field: 'operator_name',
				name: 'Opérateur',
				render: (op) => `${op}`
			}, {
				field: 'template_header',
				name: 'Nom de la communication',

			}, {
				field: 'formation_name',
				name: 'Évaluation',
			}, {
				field: 'is_read',
				name: 'Statut',
				render: (is_read) => {

					if (is_read) {
						return <EuiBadge color="success">Lue</EuiBadge>
					}

					return <EuiBadge color="warning">Non lue</EuiBadge>
				}
			}, , {
				field: 'read_on',
				name: 'Lue le',
				render: (read_on) => {
					if (read_on) {
						return <>{moment(read_on).format('DD/MM/YYYY')}</>
					}
					return <></>
				}
			}, {
				field: 'sent_on',
				name: 'Date de création',
				render: (created_at: string) => moment(created_at).format('DD/MM/YYYY')
			}, {
				field: 'is_critical',
				name: 'Type',
				render: (is_critical) => {
					if (is_critical) {
						return <EuiBadge color="accent">Critique</EuiBadge>
					}
					return <><EuiBadge color="primary">Non critique</EuiBadge></>
				}

			}]}

				pagination={{
					pageIndex: page,
					pageSize: 25,
					totalItemCount: count,
					showPerPageOptions: false,
				}}
				onChange={({ page }) => {
					setPage(page.index)
					fetch_stats(build_body({
						page: page.index
					}))
				}} />

		</div>
	</>
}
