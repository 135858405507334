
import {
	EuiBasicTable, EuiButton, EuiModalBody, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiIcon, EuiSelectable, EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiModal,
	EuiModalFooter,
	EuiModalHeader,
	EuiModalHeaderTitle, EuiPanel, EuiSpacer, EuiTitle, EuiText, EuiLoadingContent, EuiFlexGrid, EuiEmptyPrompt
} from '@elastic/eui';
import { useEffect, useState } from "react";
import { useApi } from "../../../../effects/useApi";
import { User } from "../../../../types";

interface FormationMembers {
	biologists: Array<User>,
	biologist_verificators: Array<User>,
	formation_qualiticians: Array<User>,
	formation_correctors: Array<User>,
	formation_subreferents: Array<User>,
	operators: Array<User>,

	qualiticians: Array<User>,
	correctors: Array<User>,
	laboratory_verificators: Array<User>,
	laboratory_subreferents: Array<User>,
	laboratory_biologists: Array<User>,
}

export default function RolesList({ laboratory_id, formation_id, path, refetch_formation, is_live }: { laboratory_id?: string, formation_id?: string, path: string, refetch_formation: () => void, is_live: boolean }) {
	const [members, setMembers] = useState<FormationMembers | null>(null)
	// const [laboratory_members, setLaboratoryMembers] = useState<User[] | null>(null)

	const [fetch_members] = useApi('get', `formations/${formation_id}/members`, (err, data: FormationMembers) => {
		if (err) return console.error(err)
		setMembers(data)
	})

	const [assign_roles] = useApi('post', 'users/assign_roles', (err, data) => {
		fetch_members()
	})

	const [unassign_roles] = useApi('post', 'users/unassign_roles', (err, data) => {
		fetch_members()
	})

	const [isModalVisible, setIsModalVisible] = useState(false);

	const [current_user, setCurrentUser] = useState<User | null>(null)


	const add_role = (role: string, user: User) => {
		assign_roles({ users: [user._id], role, formation_id })
		setTimeout(() => refetch_formation(), 1000)
	}

	const remove_role = (role: string, user: User) => {
		unassign_roles({ users: [user._id], role, formation_id })
		setTimeout(() => refetch_formation(), 1000)
	}

	function has_role(role: string, user: User) {
		return user[role].includes(formation_id)
	}

	let modal;

	useEffect(() => {
		fetch_members()
	}, [formation_id, laboratory_id])

	if (!members) return <EuiLoadingContent lines={5} />

	const columns = ({ key, label, plural, field_name, description, from }: { key: string, label: string, plural: string, field_name: string, description: string, from: string }) => [
		{
			field: 'name',
			name: 'Nom',
			sortable: true,
			render: (name: any, item: any) => <span>{item.firstname} {item.lastname}</span>
		}, {
			field: '_has_role',
			name: '—',
			render: (_has_role: any, item: any) => {

				let formation_role = null
				let has_formation_role = false

				if (field_name === "biologists") formation_role = "biologist_of"
				if (field_name === "biologist_verificators") formation_role = "biologist_verificator_of"
				if (field_name === "formation_qualiticians") formation_role = "formation_qualitician_of"
				if (field_name === "formation_correctors") formation_role = "formation_corrector_of"

				has_formation_role = item[formation_role].includes(formation_id)

				if (has_formation_role) return <>—</>

				const should_block_add = ((members.correctors || []).filter(m => field_name === "formation_correctors" && (m.formation_corrector_of as unknown as string[]).includes(formation_id)).length > 0 && is_live) || !item.is_active

				return <>

					<EuiButton
						iconType={has_formation_role ? 'minusInCircle' : 'plusInCircle'}
						color={has_formation_role ? 'danger' : 'primary'}
						onClick={() => has_formation_role ? remove_role(formation_role, item) : add_role(formation_role, item)}
						size="s"
						disabled={should_block_add}
					>
						{has_formation_role ? `Désassigner` : `Assigner à l'évaluations`}
					</EuiButton>
				</>
			}

		}
	]

	return <div><br />

		{/* <pre>{JSON.stringify(members, null, 2)}</pre> */}

		<EuiFlexGrid columns={2}>
			{[{
				key: "biologist",
				label: "Auditeur interne",
				plural: "Auditeur(s) interne",
				field_name: "biologists",
				description: "Vérifie les données des opérateurs",
				from: "laboratory_biologists"
			}, {
				key: "biologist_verificator",
				label: "Vérificateur",
				plural: "Vérificateur(s)",
				field_name: "biologist_verificators",
				description: "Vérifie les évaluations réalisées par les opérateurs",
				from: "laboratory_verificators"
			}, {
				key: "formation_qualitician",
				label: "Auteur",
				plural: "Auteur(s)",
				field_name: "formation_qualiticians",
				description: "Créé les évaluations",
				from: "qualiticians"
			}, {
				key: "formation_corrector",
				label: "Évaluateur",
				plural: "Évaluateur(s)",
				field_name: "formation_correctors",
				description: "Évalue la pratique des opérateurs " + (is_live ? " (uniquement un évaluateur par évaluations présentielles)" : ""),
				from: "correctors"
			}].map(({ key, label, plural, field_name, description, from }: { key: string, label: string, plural: string, field_name: string, description: string, from: string }) => <>
				<EuiFlexItem>
					<EuiPanel hasShadow={false} paddingSize="l" hasBorder>
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiTitle size="m"><h3>{plural}</h3></EuiTitle>
								<EuiText>
									<p>{description}</p>
								</EuiText>
							</EuiFlexItem>
						</EuiFlexGroup>

						{(members[from] || []).length ? <EuiBasicTable noItemsMessage="Aucun résultat trouvé" items={
							// @ts-ignore
							members[from] || []
						} columns={columns({ key, label, plural, field_name, description, from })} /> : <EuiEmptyPrompt
							titleSize='xs'
							title={<h3>Aucun {label.toLowerCase()} n'est assigné à cette entreprise</h3>}
							body={<p>L'administrateur peut assigner un {label.toLowerCase()} à l'entreprise dans la section "Membres" de l'entreprise.</p>}
						/>}
					</EuiPanel>

					<EuiSpacer size="l" />
				</EuiFlexItem>
			</>)}
		</EuiFlexGrid>
	</div>
}
