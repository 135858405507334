import { EuiBadge, EuiBasicTable, EuiButton, EuiComboBox, EuiDatePicker, EuiDatePickerRange, EuiDescribedFormGroup, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel, EuiSelect, EuiSpacer } from '@elastic/eui';
import moment from 'moment';
import { useEffect, useState } from "react";
import { API_PATH, useApi } from '../../../effects/useApi';
import { Formation, SpecialCommunicationTemplate, User } from '../../../types';



interface RehabStat {
	operator_id: string
	operator_name: string
	formation_name: string

	end_date: Date
	formation_validations: Date

	score: number
	is_late: boolean
}



export default function Rehabilitations({ path, laboratory_id, me }: { path: string, laboratory_id?: string, me: User }) {

	const [stats, setStats] = useState<RehabStat[]>([])

	const [possible_operators, setPossibleOperators] = useState<{ label: string, value?: string }[]>([])
	const [possible_formations, setPossibleFormations] = useState<{ label: string, value?: string }[]>([])
	const [possible_templates, setPossibleTemplates] = useState<{ label: string, value?: string }[]>([])

	const [operators_filter, setOperatorsFilter] = useState<{ label: string, value?: string }[]>([])
	const [formations_filter, setFormationsFilter] = useState<{ label: string, value?: string }[]>([])

	const [status_filter, setStatusFilter] = useState<string>('all')
	const [type_filter, setTypeFilter] = useState<string>('all')

	const [page, setPage] = useState<number>(0)
	const [count, setCount] = useState<number>(0)

	const [fetch_ranges] = useApi('GET', `stats/rehabilitations/${laboratory_id}/ranges`, (err, body) => {
		if (err) { console.error(err); return }
		const { possible_formations, possible_operators, possible_templates } = body
		setPossibleFormations(possible_formations.filter(formation => {
			if (me.referent_of.length > 0) return true
			if (me.biologist_of.length > 0 && me.biologist_of.includes(formation._id)) return true
			return false
		}).map((formation: Formation) => ({ _id: formation._id, label: formation.header })))
		setPossibleOperators(possible_operators.map((operator: User) => ({ label: `${operator.firstname} ${operator.lastname}`, _id: operator._id })))
		setPossibleTemplates(possible_templates.map((template: SpecialCommunicationTemplate) => ({ _id: template._id, label: template.header })))
	})

	const [fetch_stats] = useApi('GET', `stats/rehabilitations/${laboratory_id}`, (err, { stats, page, count }) => {
		if (err) { console.error(err); return }
		setStats(stats.filter(stat => {
			if (me.referent_of.length > 0) return true
			if (me.biologist_of.length > 0 && me.biologist_of.includes(stat.formation_id)) return true

			return false
		}))

		setPage(page)
		setCount(count)
	})

	const [start_date_filter, setStartDateFilter] = useState<moment.Moment | null>(null)
	const [end_date_filter, setEndDateFilter] = useState<moment.Moment | null>(null)

	function build_body(body: any) {
		return {
			page,
			// @ts-ignore
			selected_operators: operators_filter.map(({ _id }) => _id),
			// @ts-ignore
			selected_formations: formations_filter.map(({ _id }) => _id),
			status_filter: status_filter,
			start_date: start_date_filter ? start_date_filter.toDate() : null,
			end_date: end_date_filter ? end_date_filter.toDate() : null,
			selected_status: status_filter,
			...body
		}
	}


	useEffect(() => {
		fetch_ranges()
		fetch_stats()
	}, [laboratory_id, path])


	return <>
		<EuiPanel hasBorder>

			<EuiFlexGroup>
				<EuiFlexItem></EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton
						iconType="download"
						color="text"
						target="_blank"
						href={`${API_PATH}stats/rehabilitations/${laboratory_id}?${new URLSearchParams(build_body({
							format: "csv"
						})).toString()}`}
					>
						Télécharger la vue filtrée au format CSV
					</EuiButton>

				</EuiFlexItem>
			</EuiFlexGroup>

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Opérateurs</h3>}
				description={<>Laissez vide pour sélectionner tous les opérateurs de l'espace.</>}
			>
				<EuiFormRow fullWidth>
					<EuiComboBox
						placeholder="Sélectionnez un ou plusieurs opérateur(s)"
						options={possible_operators}
						// @ts-ignore
						onChange={(values) => {
							setOperatorsFilter(values);
							fetch_stats(build_body({
								// @ts-ignore
								selected_operators: values.map(({ _id }) => _id)
							}))
						}}

						selectedOptions={operators_filter}
						isClearable={true}
						fullWidth
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup >

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Évaluations</h3>}
				description={<>Laissez vide pour sélectionner toutes les évaluation de l'espace.</>}
			>
				<EuiFormRow fullWidth>
					<EuiComboBox
						placeholder="Sélectionnez une ou plusieurs évaluation(s)"
						options={possible_formations}
						// @ts-ignore
						onChange={(values) => {
							setFormationsFilter(values);
							fetch_stats(build_body({
								// @ts-ignore
								selected_formations: values.map(v => v._id),
							}))
						}}
						selectedOptions={formations_filter}
						isClearable={true}
						fullWidth
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Statut</h3>}
				description={<></>}
			>
				<EuiFormRow fullWidth>
					<EuiSelect
						fullWidth
						options={[
							{ value: 'all', text: 'Toutes' },
							{ value: 'late', text: 'Expiré' },
							{ value: 'finished', text: 'Terminées' },
							{ value: 'in_progress', text: 'En cours' },
						]}
						value={status_filter}
						onChange={(e) => {
							setStatusFilter(e.target.value)
							fetch_stats(build_body({
								selected_status: e.target.value
							}))
						}}
					/>
				</EuiFormRow>

			</EuiDescribedFormGroup>

			<EuiDescribedFormGroup
				fullWidth
				title={<h3>Date d'expiration</h3>}
				description={<></>}
			>
				<EuiFormRow fullWidth>
					<EuiDatePickerRange
						fullWidth
						startDateControl={
							<EuiDatePicker
								selected={start_date_filter}
								// @ts-ignore
								onChange={(date) => {
									setStartDateFilter(date); fetch_stats(build_body({
										start_date: date?.format('YYYY-MM-DD')
									}))
								}}
								isInvalid={start_date_filter && end_date_filter && start_date_filter.isAfter(end_date_filter)}
								dateFormat='DD/MM/YYYY'
							/>
						}
						endDateControl={
							<EuiDatePicker
								selected={end_date_filter}
								// @ts-ignore
								onChange={(date) => {
									setEndDateFilter(date); fetch_stats(build_body({
										end_date: date?.format('YYYY-MM-DD')
									}))
								}}
								isInvalid={start_date_filter && end_date_filter && start_date_filter.isAfter(end_date_filter)}
								dateFormat='DD/MM/YYYY'
							/>
						}
					/>
				</EuiFormRow>
			</EuiDescribedFormGroup>


		</EuiPanel>

		<EuiSpacer size="xxl" />

		<div>
			{/* add pagination */}
			<EuiBasicTable noItemsMessage="Aucun résultat trouvé" items={stats} columns={[
				{
					field: 'operator_name',
					name: 'Opérateur',
					render: (op) => `${op}`
				}, {
					field: 'formation_name',
					name: 'Évaluation',
				}, {
					field: 'end_date',
					name: 'Date de fin',
					render: (date) => date ? moment(date).format('DD/MM/YYYY') : "—"
				}, {
					field: 'formation_validation',
					name: 'Date de validation de l\'évaluation',
					render: (date) => date ? moment(date).format('DD/MM/YYYY') : "—"
				}, {
					field: 'score',
					name: 'Score',
					render: (score) => `${score}%`
				}, {
					field: 'status',
					name: 'Statut',
					render: (status) => {
						switch (status) {
							case 'finished':
								return <EuiBadge color="success">Terminée</EuiBadge>
							case 'in_progress':
								return <EuiBadge color="primary">En cours</EuiBadge>
							case 'late':
								return <EuiBadge color="danger">Expirée</EuiBadge>
						}
					}
					// render: (is_late, rehab) => is_late ? <EuiBadge color="danger">Expiré</EuiBadge> : (rehab.is_awaiting ? <EuiBadge color="primary">En attente</EuiBadge> : <EuiBadge color="success">Terminée</EuiBadge>)
				}
			]}

				pagination={{
					pageIndex: page,
					pageSize: 25,
					totalItemCount: count,
					showPerPageOptions: false,
				}}
				onChange={({ page }) => {
					setPage(page.index)
					fetch_stats(build_body({
						page: page.index
					}))
				}} />

		</div>
	</>
}